import React, { useCallback, useEffect, useMemo, useState } from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Hidden, { HiddenProps } from "@material-ui/core/Hidden";

import MoreVert from "@material-ui/icons/MoreVert";

import { getIn } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardArrowUp, KeyboardArrowDown } from "@material-ui/icons";
import { Grid, IconButton } from "@material-ui/core";
import { compareSorting } from "./utils";
import clsx from "clsx";

const useStyles = makeStyles(
	theme => ({
		root: {},
		expandedColumn: {
			position: "relative" as "relative"
		},
		editColumnCommon: {
			width: 50,
			minWidth: 50,
			maxWidth: 50,
			"&:last-child": {
				padding: 0
			}
		},
		editColumnHeaderCell: (props: { onRowClick?: any }) => ({
			cursor: props.onRowClick ? "pointer" : "auto"
		}),
		editColumnRowCell: (props: { onRowClick?: any }) => ({
			"&:hover": {
				cursor: "pointer",
				background: props.onRowClick
					? theme.palette.grey[300]
					: theme.palette.grey[200]
			}
		}),
		emptyLastColumn: {
			width: 50,
			minWidth: 50,
			maxWidth: 50,
			"&:last-child": {
				padding: 0
			}
		},
		editIcon: {
			width: "100%"
		},
		clickableRow: {
			"&:hover": {
				cursor: "pointer",
				background: theme.palette.grey[200]
			}
		},
		head: {},
		columnLabel: {
			alignSelf: "center",
			paddingRight: 8
		},
		sortArrowsContainer: {
			height: 20,
			width: 20
		}
	}),
	{ name: "DployExpandableTable" }
);

export interface RowProps<
	TData extends object,
	TLoaded extends object | unknown = unknown
> {
	children?: React.ReactNode;
	rowData: TData;
	loadedData?: TLoaded;
}

export interface CellDataRenderer<
	TData extends object,
	TLoaded extends object | unknown = unknown
> {
	(cell: any, row: TData, loaded: TLoaded | undefined): React.ReactNode;
}

export interface HiddenMethod<
	TData extends object,
	TLoaded extends object | unknown = unknown
> {
	(cell: any, row: TData, loaded: TLoaded | undefined): boolean;
}

export enum ColumnSortOrderEnum {
	Disabled = -1,
	NotSet = 0,
	Asc = 1,
	Desc = 2
}

export interface ColumnDefinition<
	TData extends object,
	TLoaded extends object | unknown = unknown
> {
	id?: string;
	loadedId?: string;
	hidden?: HiddenProps | boolean | HiddenMethod<TData, TLoaded>;
	hiddenInExpandable?: HiddenProps | boolean | HiddenMethod<TData, TLoaded>;
	label?: React.ReactNode;
	format?: CellDataRenderer<TData, TLoaded>;
	tooltip?: CellDataRenderer<TData, TLoaded>;
	component?: React.ComponentType<RowProps<TData, TLoaded>>;
	expandedComponent?: React.ComponentType<RowProps<TData, TLoaded>>;
	className?: string;
	props?: object;
	sortOrder?: ColumnSortOrderEnum;
}

export interface ExpandableTableProps<
	TData extends object,
	TLoaded extends object | unknown = unknown
> {
	tableData: TData[];
	columnData: ColumnDefinition<TData, TLoaded>[];
	idColumn?: string;
	onRowClick?: ((row: TData) => void) | null;
	renderExpandedSection?: (data: TData) => React.ReactNode;
	tableType?: string;
	expandable?: boolean;
	classes?: Partial<ReturnType<typeof useStyles>>;
	size?: "medium" | "small" | undefined;
	tableIsSortable?: boolean;
}

export const ExpandableTable = <
	TData extends object,
	TLoaded extends object | unknown = unknown
>(
	props: ExpandableTableProps<TData, TLoaded>
) => {
	const {
		tableData,
		columnData,
		idColumn = "id",
		onRowClick,
		renderExpandedSection,
		tableType,
		expandable = true,
		size,
		tableIsSortable
	} = props;
	const [expanded, setExpanded] = useState<Record<string, boolean>>({});

	const classes = useStyles(props);

	const onMoreInfoClick = (row: TData) => (event: React.MouseEvent) => {
		event.stopPropagation();
		return setExpanded(state => {
			const id = row[idColumn];
			if (state[id]) {
				const { [id]: _, ...expanded } = state;
				return expanded;
			} else {
				return {
					...state,
					[id]: true
				};
			}
		});
	};

	const [filteredColumns, setFilteredColumns] = useState(columnData);
	const [orderedRows, setOrderedRows] = useState(tableData);

	useEffect(() => {
		const columnsWithSorting = filteredColumns.filter(
			x => (x.sortOrder ?? 0) > 0
		);
		const columnToSortBy = columnsWithSorting[0];
		if (columnsWithSorting.length > 1) {
			console.warn(
				`There are more than one columns in ExpandableTable that are marked as sorting columns. Using the first column in table as sorting column (${columnToSortBy})`
			);
		}
		if (tableIsSortable && columnToSortBy) {
			var copiedTabledDataToMutate = Object.assign([], tableData);
			setOrderedRows(
				copiedTabledDataToMutate.sort((a: TData, b: TData) => {
					return compareSorting(
						a[columnToSortBy.id ?? ""],
						b[columnToSortBy.id ?? ""],
						columnToSortBy.sortOrder!
					);
				})
			);
		} else {
			//	If no column is marked as sorting, use default tableData order from props
			setOrderedRows(tableData);
		}
	}, [filteredColumns, tableIsSortable, tableData]);

	useEffect(() => {
		setFilteredColumns(
			columnData
				.filter(col => col.hidden !== true && col.id)
				.map(col => {
					return {
						...col,
						sortOrder: col.sortOrder ?? ColumnSortOrderEnum.NotSet
					};
				})
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// NotSet -> Asc -> Desc -> NotSet -> ...
	const calculateLoopingSortOrder = useMemo(
		() => (sortOrder: number) => {
			let sum = sortOrder + 1;
			if (sum > 2) sum = 0;
			else if (sum < 0) sum = 2;
			return sum;
		},
		[]
	);

	const handleSortIconClick = useCallback(
		(selectedColumn: ColumnDefinition<TData, TLoaded>) => {
			setFilteredColumns(
				filteredColumns.map(x => {
					return {
						...x,
						sortOrder:
							x.id === selectedColumn.id
								? (calculateLoopingSortOrder(
										x.sortOrder!
								  ) as ColumnSortOrderEnum)
								: (x.sortOrder ?? 0) > 0
								? ColumnSortOrderEnum.NotSet
								: x.sortOrder
					};
				})
			);
		},
		[calculateLoopingSortOrder, filteredColumns]
	);

	return (
		<Table size={size} className={classes.root} data-tabletype={tableType}>
			<Hidden xsDown>
				<TableHead>
					<TableRow>
						{filteredColumns.map((col, i) => (
							<Hidden
								key={i}
								{...(typeof col.hidden === "object" ? col.hidden : null)}
							>
								<TableCell classes={{ head: classes.head }}>
									<Grid container direction="row">
										<Grid item className={classes.columnLabel}>
											{col.label}
										</Grid>
										{tableIsSortable && (col.sortOrder ?? 0) > -1 && (
											<Grid
												item
												container
												direction="column"
												style={{ width: "min-content" }}
											>
												{col.sortOrder === ColumnSortOrderEnum.NotSet ? (
													<>
														<ColumnSortArrow
															className={classes.sortArrowsContainer}
															upArrow
															onClick={handleSortIconClick}
															column={col}
														/>
														<ColumnSortArrow
															className={classes.sortArrowsContainer}
															onClick={handleSortIconClick}
															column={col}
														/>
													</>
												) : col.sortOrder === ColumnSortOrderEnum.Asc ? (
													<ColumnSortArrow
														className={classes.sortArrowsContainer}
														onClick={handleSortIconClick}
														column={col}
													/>
												) : col.sortOrder === ColumnSortOrderEnum.Desc ? (
													<ColumnSortArrow
														className={classes.sortArrowsContainer}
														upArrow
														onClick={handleSortIconClick}
														column={col}
													/>
												) : null}
											</Grid>
										)}
									</Grid>
								</TableCell>
							</Hidden>
						))}
						{expandable && (
							<TableCell
								className={clsx(
									classes.editColumnCommon,
									classes.editColumnHeaderCell
								)}
							/>
						)}
					</TableRow>
				</TableHead>
			</Hidden>
			<TableBody>
				{orderedRows &&
					orderedRows.map(row => (
						<React.Fragment key={row[idColumn]}>
							<TableRow
								onClick={onRowClick ? () => onRowClick(row) : undefined}
								className={onRowClick ? classes.clickableRow : undefined}
							>
								{filteredColumns.map(col => {
									const CellComponent = col.component || DefaultCell;
									const cellData = getIn(row, col.id!);

									const value = col.format
										? col.format(cellData, row, undefined)
										: cellData;

									return (
										<Hidden
											key={col.id?.toString() ?? col.loadedId?.toString()}
											{...(typeof col.hidden === "object" ? col.hidden : null)}
										>
											<TableCell className={col.className}>
												{CellComponent ? (
													<CellComponent rowData={row} {...col.props}>
														{value}
													</CellComponent>
												) : (
													value
												)}
											</TableCell>
										</Hidden>
									);
								})}
								{expandable && (
									<TableCell
										className={clsx(
											classes.editColumnCommon,
											classes.editColumnRowCell
										)}
										onClick={onMoreInfoClick(row)}
									>
										<MoreVert className={classes.editIcon} />
									</TableCell>
								)}
							</TableRow>
							{expanded[row[idColumn]] ? (
								<TableRow>
									<TableCell
										colSpan={columnData.length + 1}
										className={classes.expandedColumn}
									>
										{renderExpandedSection && renderExpandedSection(row)}
									</TableCell>
								</TableRow>
							) : null}
						</React.Fragment>
					))}
			</TableBody>
		</Table>
	);
};

const DefaultCell = ({ children }) => <span>{children}</span>;

interface ColumnSortArrowProps<
	TData extends object,
	TLoaded extends object | unknown = unknown
> {
	className: string;
	upArrow?: boolean;
	onClick: (column: ColumnDefinition<TData, TLoaded>) => void;
	column: ColumnDefinition<TData, TLoaded>;
}

const ColumnSortArrow = <
	TData extends object,
	TLoaded extends object | unknown = unknown
>(
	props: ColumnSortArrowProps<TData, TLoaded>
) => {
	const { upArrow, className, onClick, column } = props;
	return (
		<Grid item className={className}>
			<IconButton className={className} onClick={() => onClick(column)}>
				{upArrow ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
			</IconButton>
		</Grid>
	);
};
