import { apiResourceUrl } from "@ploy-lib/core";
import { BaseResource } from "./BaseResource";
import { ID } from "@ploy-lib/types";
import { Entity, Resource } from "@rest-hooks/rest";

export enum ValidatorEnumTypes {
	NotSet = "NotSet",
	Visibility = "Visibility"
	// Required = "Required" // TODO
}

export const ValidatorDescriptions: Record<
	ValidatorEnumTypes,
	{
		description: string;
		shortHelperText: string;
		defaultOption?: string;
	}
> = {
	[ValidatorEnumTypes.NotSet]: {
		description: "",
		shortHelperText: ""
	},
	[ValidatorEnumTypes.Visibility]: {
		description: "Connected fields are visible when the Target field's value",
		shortHelperText: "are visible when",
		defaultOption: "Default visible when conditions are not met?"
	}
	// [ValidatorEnumTypes.Required]: {
	// 	description: "Connected fields are required when the Target field's value",
	// 	shortHelperText: "are required when",
	// 	defaultOption: "Default required when conditions are not met?"
	// }
};

// Individual Validators
export interface IFormValidator {
	formValidatorId: ID;
	name: string;
	validatorType: ValidatorEnumTypes;
	errorMessage?: string;
	changeDate?: string;
	description?: string;

	operator?: string;
	evaluationValue?: string;
}

export class FormValidatorResource
	extends BaseResource
	implements IFormValidator
{
	readonly formValidatorId: ID = "";
	readonly name: string = "";
	readonly validatorType: ValidatorEnumTypes = ValidatorEnumTypes.NotSet;
	readonly errorMessage?: string = "";
	readonly changeDate?: string = undefined;
	readonly description?: string = undefined;
	readonly helperText?: string = undefined;

	readonly operator?: string = undefined;
	readonly evaluationValue?: string = undefined;

	pk() {
		return this.formValidatorId?.toString();
	}

	static detail<T extends typeof Resource>(this: T) {
		const endpoint = super.detail();
		return endpoint.extend({
			url: urlParams => {
				return endpoint.url(urlParams);
			}
		});
	}

	static urlRoot = apiResourceUrl("form-validator");
}

// ValidatorConnections
export interface IFormValidatorToFormTemplate {
	formValidatorToFormId: ID;
	formTemplateId: ID;
	formValidatorId: ID;
	connectedFields?: string[];
	targetField?: string;
	description?: string;
}

export class FormValidatorToFormTemplateResource
	extends BaseResource
	implements IFormValidatorToFormTemplate
{
	readonly formValidatorToFormId: ID = "";
	readonly formTemplateId: ID = "";
	readonly formValidatorId: ID = "";
	readonly connectedFields?: string[] = undefined;
	readonly targetField?: string = "";
	readonly description?: string = "";

	pk() {
		return this.formValidatorToFormId?.toString();
	}

	static detail<T extends typeof Resource>(this: T) {
		const endpoint = super.detail();
		return endpoint.extend({
			url: urlParams => {
				return endpoint.url(urlParams);
			}
		});
	}

	static urlRoot = apiResourceUrl("form-validator-to-form-template");
}

// Form validation
export interface IFormValidationExpression {
	readonly _owningFieldName: string;
	readonly operator: string;
	readonly evaluationValue: string;
	readonly targetField: string;
	readonly validatorType: string;
}

export class FormValidationExpression
	extends Entity
	implements IFormValidationExpression
{
	pk = () => this._owningFieldName;

	_owningFieldName: string = "";
	evaluationValue: string = "";
	operator: string = "";
	targetField: string = "";
	validatorType: string = "";
}

export interface IFormValidation {
	readonly expressions?: Record<string, IFormValidationExpression>;
}

export class FormValidationResource
	extends BaseResource
	implements IFormValidation
{
	expressions?: Record<string, IFormValidationExpression>;

	pk() {
		return "form-validations_singleton";
	}

	static formValidations<T extends typeof Resource>(this: T) {
		return this.detail().extend({
			url: urlParams => `${this.urlRoot}/get-form-validations/${urlParams}`
		});
	}

	static urlRoot = apiResourceUrl("resolve-form-validations");
}
