import React from "react";
import camelcaseKeys from "camelcase-keys";
import { getQueryParams } from "./utils";
import { legacyApiResourceUrl } from "@ploy-lib/core";

export interface RegistrationObject {
	isFound: boolean;
	message: string;
	contractNo: string;
	contractEndDate: Date;
	contractStartDate: Date;
	readingDate: string;
	deliveryDate: Date;
	customerName: string;
	registrationNo: string;
	description: string;
	distanceKm: number;
	valueAmount: number;
	priceExtraKm: number;
	registeredKilometer: number;
	mileage: number;
	mvaPercentage: number;
	kmReadingAvailable: boolean;
	objectType: number;
	isApproved: boolean;
	emailComment: string;
}

export interface KeyValuePair<T> {
	key: string;
	value: T;
}

export interface RegistrationResult {
	success: boolean;
	message: string;
	kmReading: number;
	isApproved: boolean;
	readingDate: string;
}

export interface RegistrationObjectSearchResult {
	success: boolean;
	message?: string;
	list: RegistrationObject[];
}

export interface GetCalculationDetailsResponse {
	priceExtraKilometer: number;
	mileage: number;
	vatPercentage: number;
	kilometerReadingAvailable?: boolean;
}

export interface SendMessageResult {
	success: boolean;
}

export interface API {
	search(params: {
		carRegistrationNo: string;
		fromDate: string;
		toDate: string;
		vendor: string;
		backofficeId: string;
	}): Promise<RegistrationObjectSearchResult>;
	register(params: {
		contractNo: string;
		reading: number;
		readingDate: string;
		priceExtraKm: number;
		registrationNo: string;
		customerName: string;
		invoiceAmount: number;
		objectType: number;
		deliveryDate: Date;
		allowedKm: number;
		distanceKm: number;
		emailComment: string;
	}): Promise<RegistrationResult>;
	vendorSearch(searchString: string): Promise<KeyValuePair<string>[]>;
	getCalculationDetails(
		carRegistrationNo: string,
		vendor: string
	): Promise<GetCalculationDetailsResponse>;
	sendMessage(params: object): Promise<SendMessageResult>;
}

export interface KmReadingAPIProps {
	children(api: API): React.ReactElement<any>;
	token: string;
	endpoint: string;
}

export class KmReadingAPI extends React.Component<KmReadingAPIProps> {
	callAPI = async (method: string, action: string, params: object) => {
		const { token } = this.props;

		const useBodyContent = method !== "GET" && method !== "HEAD";

		const finalUrl = useBodyContent
			? `/api/km-reading/${action}`
			: `/api/km-reading/${action}?${getQueryParams(params)}`;

		const response = await fetch(finalUrl, {
			method,
			headers: token ? new Headers({ Authorization: token }) : undefined,
			credentials: "include", // Include session cookie
			body: useBodyContent
				? new Blob([JSON.stringify(params)], { type: "application/json" })
				: null
		});
		const data = await response.json();
		return camelcaseKeys(data) as any;
	};

	handleSendMessage: API["sendMessage"] = async (
		params: object
	): Promise<SendMessageResult> => {
		try {
			var response = await fetch(
				legacyApiResourceUrl("Message/RunExternalDistributionMatrix"),
				{
					method: "POST",
					credentials: "include", // Include session cookie
					body: new Blob([JSON.stringify(params)], { type: "application/json" })
				}
			);
			return response.json();
		} catch (err: any) {
			throw Error("Error when searching vendors. " + err.message);
		}
	};

	handleSearch: API["search"] = async ({
		carRegistrationNo,
		fromDate,
		toDate,
		vendor,
		backofficeId
	}): Promise<RegistrationObjectSearchResult> => {
		try {
			const response = await this.callAPI("GET", "search-object", {
				CarRegistrationNo: carRegistrationNo,
				ContractEndDateFrom: fromDate,
				ContractEndDateTo: toDate,
				VendorOrganizationNo: vendor,
				VendorBackofficeNo: backofficeId
			});

			return {
				...response,
				list: response.list.map((x: any) => ({
					...x,
					ContractEndDate: new Date(x.ContractEndDate)
				}))
			};
		} catch (err: any) {
			throw Error("Error searching km reading object. " + err.message);
		}
	};

	handlegetCalculationDetails: API["getCalculationDetails"] = async (
		carRegistrationNo: string,
		vendor: string
	): Promise<GetCalculationDetailsResponse> => {
		try {
			const response = await this.callAPI("GET", "calculation-details", {
				carRegistrationNo,
				vendor
			});
			return response;
		} catch (err: any) {
			throw Error("Error searching km reading object. " + err.message);
		}
	};

	handleRegister: API["register"] = async ({
		contractNo,
		reading,
		readingDate,
		priceExtraKm,
		customerName,
		registrationNo,
		invoiceAmount,
		objectType,
		deliveryDate,
		distanceKm,
		allowedKm,
		emailComment
	}): Promise<RegistrationResult> => {
		try {
			const response = await this.callAPI("PUT", "register", {
				contractNo,
				kmReading: reading,
				kmReadingDate: readingDate,
				priceExtraKm,
				customerName,
				registrationNo,
				invoiceAmount,
				objectType,
				deliveryDate,
				distanceKm,
				allowedKm,
				emailComment
			});
			return response;
		} catch (err: any) {
			throw Error("Error registering km reading. " + err.message);
		}
	};

	handleSearchVendor: API["vendorSearch"] = async (
		searchString: string
	): Promise<KeyValuePair<string>[]> => {
		try {
			var response = await fetch(
				legacyApiResourceUrl(
					`/VendorSelect/SearchVendorsByPartialName?data=${encodeURIComponent(
						searchString
					)}`
				),
				{
					method: "GET",
					credentials: "include" // Include session cookie
				}
			);
			const data = await response.json();
			return camelcaseKeys(data) as any;
		} catch (err: any) {
			throw Error("Error when searching vendors. " + err.message);
		}
	};

	render() {
		const { children } = this.props;

		return children({
			search: this.handleSearch,
			register: this.handleRegister,
			vendorSearch: this.handleSearchVendor,
			getCalculationDetails: this.handlegetCalculationDetails,
			sendMessage: this.handleSendMessage
		});
	}
}

export default KmReadingAPI;
