import { useState, useEffect } from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { GlobalSuspense } from "@ploy-ui/core";

import AppFilterSelector from "./AppFilterSelector";
import SearchInputs from "./SearchInputs";
import SearchResults from "./SearchResults";
import AdvancedSearchToggle from "./AdvancedSearchToggle";
import Hidden from "@material-ui/core/Hidden";

import { useAppSearchParams } from "../../../contexts";
import {
	ProfileResource,
	AllApplicationsSettingsResource
} from "@ploy-lib/rest-resources";
import { useResource } from "@rest-hooks/core";
import { Formik, Form, useFormikContext } from "formik";

function SubmitOnUnmount() {
	const { submitForm } = useFormikContext();

	useEffect(() => () => void submitForm(), [submitForm]);

	return null;
}

export function AllApplications({ className }: { className?: string }) {
	const appSearchParams = useAppSearchParams();

	const [advancedSearchOpen, setAdvancedSearchOpen] = useState(
		appSearchParams.searchParams != null
	);

	const classes = useStyles();

	const profile = useResource(ProfileResource.detail(), {});

	const { allowVendorHierarchySearch } = useResource(
		AllApplicationsSettingsResource.detail(),
		{}
	);

	const defaultValues = {
		salepersonId: profile.salespersonId,
		searchInVendorHierarchy: allowVendorHierarchySearch || undefined,
		fullText: "",
		carRegNo: "",
		customerName: ""
	};
	const initialValues = appSearchParams.searchParams ?? defaultValues;

	return (
		<Formik
			onReset={(values, formik) => {
				appSearchParams.resetSearchParams(defaultValues);
			}}
			onSubmit={values => appSearchParams.set(values)}
			initialValues={initialValues}
			enableReinitialize
		>
			<Form className={clsx(classes.root, className)}>
				<SubmitOnUnmount />
				{advancedSearchOpen ? (
					<SearchInputs setAdvancedSearchOpen={setAdvancedSearchOpen} />
				) : null}
				<div className={clsx(classes.rightContainer)}>
					<div className={classes.topContainer}>
						{advancedSearchOpen ? (
							<Hidden mdDown>
								<AdvancedSearchToggle
									open={true}
									onClick={() => setAdvancedSearchOpen(false)}
								/>
							</Hidden>
						) : (
							<AdvancedSearchToggle
								open={false}
								onClick={() => setAdvancedSearchOpen(true)}
							/>
						)}

						{!advancedSearchOpen ? (
							<AppFilterSelector name="salepersonId" />
						) : null}
					</div>

					<GlobalSuspense>
						<SearchResults />
					</GlobalSuspense>
				</div>
			</Form>
		</Formik>
	);
}

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		width: "100%",
		flexDirection: "column",
		[theme.breakpoints.up("lg")]: {
			flexDirection: "row"
		}
	},
	rightContainer: {
		flexGrow: 1,
		[theme.breakpoints.up("sm")]: {
			padding: theme.spacing(2)
		},
		[theme.breakpoints.up("lg")]: {
			padding: theme.spacing(4)
		}
	},
	topContainer: {
		display: "flex",
		flexWrap: "wrap",
		flexDirection: "row-reverse",
		marginBottom: 20,
		padding: "1rem .5rem 0 .5rem",
		[theme.breakpoints.up("sm")]: {
			padding: 0
		}
	}
}));

export default AllApplications;
