import takeWhile from "lodash/takeWhile";
import {
	FormTemplate,
	TemplatePage,
	TemplatePageBase,
	TemplatePageDeprecated,
	TemplateSection
} from "@ploy-lib/types";
import {
	convertMultiColumn,
	convertFieldList,
	convertSummaryPage,
	convertCalculator,
	convertFieldListWithoutWizard
} from "./convertTemplateLayouts";
import { hasPanels } from "./utils";
import { convertPostProcessPage } from "./convertPostProcess";
import { v4 as uuidv4 } from "uuid";
import fromPairs from "lodash/fromPairs";

export function convertTemplate(
	template: FormTemplate,
	formContext: string | undefined
): FormTemplate<TemplatePage> {
	const originalPages = template.pages;

	let convertedPages: TemplatePage[] = convertVulcanPagesToPhoenix(
		originalPages,
		formContext
	);

	//Map all sections and give them an id
	convertedPages = setSectionIdsIfNotExisting(convertedPages);

	// Convert page panels where some sections are still using the old expansionGroup variable, delete me when nobody is using expansionGroup
	convertedPages = convertSectionsUsingOldExpansionGroup(convertedPages);

	return {
		...template,
		pages: convertedPages
	};
}

function convertVulcanPagesToPhoenix(
	originalPages: TemplatePageBase[],
	formContext?: string
) {
	let convertedPages: TemplatePage[] = [];

	for (let i = 0; i < originalPages.length; ++i) {
		const page = originalPages[i];
		if (hasPanels(page)) {
			convertedPages.push(page);
		} else if (formContext && formContext.toLowerCase() === "calculator") {
			switch (originalPages[i].layout) {
				case "FieldList":
				case "FieldListWithSidebar": {
					const pages = takeWhile(
						originalPages.slice(i),
						p =>
							!p.layout ||
							["FieldList", "FieldListWithSidebar"].includes(p.layout)
					);
					i += pages.length - 1;

					convertedPages.push(
						convertCalculator(pages as TemplatePageDeprecated[])
					);
					break;
				}
			}
		} else {
			switch (originalPages[i].layout) {
				case "FieldListMultiColumn": {
					let numberOfColumns = 0;
					for (const p of originalPages.slice(i)) {
						if (
							p.layout !== "FieldListMultiColumn" ||
							numberOfColumns >= 3 ||
							hasPanels(p)
						)
							break;
						numberOfColumns++;
					}

					const columns = originalPages.slice(i, numberOfColumns);
					i += columns.length - 1;

					convertedPages.push(
						convertMultiColumn(columns as TemplatePageDeprecated[])
					);

					break;
				}
				case "FieldList":
				case "FieldListWithSidebar": {
					convertedPages.push(convertFieldList(page as TemplatePageDeprecated));
					break;
				}
				case "SummaryPage": {
					convertedPages.push(
						convertSummaryPage(page as TemplatePageDeprecated)
					);
					break;
				}
				case "FieldListOnly":
				case "FieldListWithoutWizard": {
					convertedPages.push(
						convertFieldListWithoutWizard(page as TemplatePageDeprecated)
					);
					break;
				}
				case "PostProcessNew": {
					convertedPages.push(
						convertPostProcessPage(page as TemplatePageDeprecated)
					);
					break;
				}
			}
		}
	}

	return convertedPages;
}

function setSectionIdsIfNotExisting(convertedPages: TemplatePage[]) {
	return convertedPages.map(
		(p): TemplatePage => ({
			...p,
			panels: fromPairs(
				Object.entries(p.panels).map(([panelname, panel]) => {
					const sections = (panel.sections ?? []).map(
						(s): TemplateSection =>
							!s.formTemplateSectionId
								? {
										...s,
										formTemplateSectionId: uuidv4()
								  }
								: s
					);

					return [
						panelname,
						{
							...panel,
							sections
						}
					];
				})
			)
		})
	);
}

function convertSectionsUsingOldExpansionGroup(convertedPages: TemplatePage[]) {
	return convertedPages.map(
		(p): TemplatePage => ({
			...p,
			panels: fromPairs(
				Object.entries(p.panels).map(([panelname, panel]) => {
					let sections = panel.sections;
					const anySectionsWithOldExpansionGroup = sections.some(
						s => s.expansionGroup != null
					);

					if (!anySectionsWithOldExpansionGroup) {
						// No need to continue conversion when there is nothing to do
						return [panelname, panel];
					}

					sections = sections.map(s => ({ ...s }));

					// Group sections where expansionGroup has non-null value
					let groupedSections = sections
						.map((s, idx) => [s.expansionGroup, idx] as const)
						.filter((vals): vals is [string, number] => vals[0] != null)
						.reduce((acc, [g, idx]) => {
							const vals = (acc[g] = acc[g] ?? []);
							vals.push(idx);
							return acc;
						}, {} as Record<string, number[]>);

					for (const [groupName, indexes] of Object.entries(groupedSections)) {
						const firstSection = sections[indexes[0]];
						firstSection.displaySectionTitle = true;

						for (const sectionIdx of indexes) {
							const section = sections[sectionIdx];
							section.group = groupName;
							section.expansionGroup = undefined;

							// Set common settings for old expansion groups
							section.enableExpansion = true;

							if (
								section.sectionTitle &&
								section.sectionTitle === section.group
							) {
								section.sectionTitle = undefined;
							}
						}
					}

					return [
						panelname,
						{
							...panel,
							sections
						}
					];
				})
			)
		})
	);
}
