import { useMemo } from "react";
import { useCalculationField } from "@ploy-lib/calculation";
import { useDispatch } from "@ploy-lib/calculation";
import { AbstractInstanceType, SimpleResource } from "@rest-hooks/rest";
import { useFetcher, useResource } from "@rest-hooks/core";
import { useOptionSourceFields } from "./utils";
import { OptionValue, TableHelperFields } from "./types";
import { useField } from "formik";
import { TemplateTableColumn } from "@ploy-lib/types";
import { TableColumnMetadata } from "./tableSchema";

export interface DataProviderProps<TData extends {} = {}> {
	rows: (TData & TableHelperFields)[];
	tableColumns: TemplateTableColumn[];
	saveElement?: (
		values: (TData & TableHelperFields)[],
		elementIndex?: number
	) => void;
	deleteElement?: (
		values: (TData & TableHelperFields)[],
		elementIndex?: number
	) => void;
	extendedMetadata?: Partial<Record<string, TableColumnMetadata<TData>>>;
}

const identityArrayWithDefault = <Data,>(
	x: Readonly<Data[]> | null | undefined
) => x || [];
const identity = <Data,>(x: Data) => x;

interface CalcRulesDataProviderProps<TRowCells extends {}, TStorageData, TRawStorage> {
	tableType: string;
	tableColumns?: TemplateTableColumn[];
	namespace?: string;
	name: string;
	children: (props: DataProviderProps<TRowCells>) => JSX.Element;
	/**
	 * Look up row backing storage in variable from calc rules. Useful when underlying structure is more complex than just a list of rows.
	 * Returns possibly complex data considered to be handled as a row
	 */
	convertRawStorageToStorageDataRows?: (
		rawStorage: Readonly<TRawStorage> | undefined | null
	) => Readonly<TStorageData[]>;

	/**
	 * Handle conversion of backing row data to proper row cells.
	 */
	convertStorageDataToRowCells?: (
		storageData: TStorageData
	) => TRowCells & TableHelperFields;

	/**
	 * Handle conversion of row cells to backing row data.
	 */
	convertRowCellsToStorageData?: (row: TRowCells) => TStorageData;

	/**
	 * Create an updated version of underlying variable
	 */
	convertStorageDataRowsToRawStorage?: (
		rows: TStorageData[],
		currentRawStorage: Readonly<TRawStorage> | null | undefined
	) => Readonly<TRawStorage>;
}

export const CalcRulesDataProvider = <TRowData extends {}, TStorageData, TRawStorage>(
	props: CalcRulesDataProviderProps<TRowData, TStorageData, TRawStorage>
) => {
	const {
		tableType,
		tableColumns = [],
		namespace,
		name,
		children,
		convertRawStorageToStorageDataRows = identityArrayWithDefault as NonNullable<
			typeof props["convertRawStorageToStorageDataRows"]
		>,
		convertStorageDataToRowCells = identity as NonNullable<
			typeof props["convertStorageDataToRowCells"]
		>,
		convertRowCellsToStorageData = identity as NonNullable<
			typeof props["convertRowCellsToStorageData"]
		>,
		convertStorageDataRowsToRawStorage = identity as NonNullable<
			typeof props["convertStorageDataRowsToRawStorage"]
		>
	} = props;

	const [field, , { setValue }] = useField<TRawStorage>(name);
	const { value: originalValues = field.value } =
		useCalculationField<TRawStorage>({
			namespace,
			name: tableType
		});

	const columnsWithOptionSource = tableColumns.filter(
		col => col.optionSource !== undefined
	);
	const optionSources: Record<string, OptionValue<string, string>[]> =
		useOptionSourceFields(columnsWithOptionSource);
	const columnsWithOptionValues = tableColumns.map(col => {
		const optionValueCandidate = optionSources[`${col.tableType}.${col.name}`];
		col.optionValues =
			col.optionSource && Array.isArray(optionValueCandidate)
				? optionValueCandidate
				: col.optionValues || [];

		return col;
	});

	const dispatch = useDispatch();

	const relevantDataRows = useMemo(
		() => convertRawStorageToStorageDataRows(originalValues),
		[convertRawStorageToStorageDataRows, originalValues]
	);

	const relevantCellRows = useMemo(
		() => relevantDataRows.map(convertStorageDataToRowCells),
		[convertStorageDataToRowCells, relevantDataRows]
	);

	return children({
		rows: relevantCellRows,
		tableColumns: columnsWithOptionValues,
		saveElement: (values, elementIndex) => {
			const convertedValues = convertStorageDataRowsToRawStorage(
				values.map(convertRowCellsToStorageData),
				originalValues
			);
			dispatch({
				type: "patch",
				payload: {
					patches: [
						{
							target: tableType,
							namespace,
							value: convertedValues,
							overwrite: true,
							changeTrigger: `${tableType}_manualSave`
						}
					]
				}
			});
			if (field) {
				setValue(convertedValues);
			}
		},
		deleteElement: (values, elementIndex) => {
			const convertedValues = convertStorageDataRowsToRawStorage(
				values
					.filter((v, idx) => idx !== elementIndex)
					.map(convertRowCellsToStorageData),
				originalValues
			);
			dispatch({
				type: "patch",
				payload: {
					patches: [
						{
							target: tableType,
							namespace,
							value: convertedValues,
							overwrite: true,
							changeTrigger: `${tableType}_manualSave`
						}
					]
				}
			});
			if (field) {
				setValue(convertedValues);
			}
		}
	});
};

interface ResourceDataProviderProps<T extends typeof SimpleResource> {
	resource: T;
	resourceId: string;
	tableColumns: TemplateTableColumn[];
	children: (props: DataProviderProps<AbstractInstanceType<T>>) => JSX.Element;
}
export const ResourceDataProvider = <T extends typeof SimpleResource>(
	props: ResourceDataProviderProps<T>
) => {
	type TData = AbstractInstanceType<T>;
	const { resource, resourceId, tableColumns, children } = props;

	const data = useResource(resource.list(), {});
	const create = useFetcher(resource.create());
	const update = useFetcher(resource.update());
	const del = useFetcher(resource.delete());

	const isNewElement = (value: TData & TableHelperFields) => {
		// Assume new element if it does not have a key
		return !!value.pk();
	};

	const onRowSave = (entity: TData & TableHelperFields) => {
		if (isNewElement(entity)) {
			create({}, entity, [
				[resource.list(), {}, (newId, oldIds) => [...oldIds, newId]]
			]);
		} else {
			update({ [resourceId]: entity[resourceId] }, entity);
		}
	};
	const onRowDelete = (entity: TData) => {
		del({ [resourceId]: entity[resourceId] });
	};

	const rows = Array.isArray(data)
		? data
		: Array.isArray((data as any).records)
		? (data as any).records
		: [];

	return children({
		rows,
		tableColumns: tableColumns,
		saveElement: (values, index) => {
			index !== undefined && onRowSave(values[index]);
		},
		deleteElement: (values, index) => index && onRowDelete(values[index])
	});
};
