import React, { useState, useMemo } from "react";
import CheckIcon from "@material-ui/icons/Check";
import {
	Paper,
	Grid,
	Typography,
	Avatar,
	Box,
	Hidden,
	IconButton
} from "@material-ui/core";
import { useFetcher, useResource } from "@rest-hooks/core";
import {
	PasswordResetResource,
	ProfileResource,
	VulcanDataResource
} from "@ploy-lib/rest-resources";
import { TextField } from "@ploy-ui/form-fields";
import { Formik, Field, Form } from "formik";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { PendingButton } from "@ploy-ui/core";
import { object, string, ref } from "yup";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";

const messages = defineMessages({
	required: {
		id: "dealer.profile.validation.required",
		defaultMessage: "Required"
	},
	confirmMustBeEqual: {
		id: "dealer.profile.validation.confirm-password-not-equal",
		defaultMessage: "Verified password does not equal the new password"
	},
	mobileValidation: {
		id: "dealer.profile.validation.mobileNumber",
		defaultMessage: "Mobile number must contain 8 digits"
	},
	emailWarning: {
		id: "dealer.profile.email.changeWarning",
		defaultMessage:
			"Changing your email address will also change your username",
		description: "Warning text for when editing Saleperson's email address"
	}
});

const ChangePasswordForm = () => {
	const updatePassword = useFetcher(PasswordResetResource.updatePassword());

	const intl = useIntl();

	const passwordChangeSchema = useMemo(
		() =>
			object().shape({
				oldPassword: string().required(intl.formatMessage(messages.required)),
				newPassword: string().required(intl.formatMessage(messages.required)),
				newPasswordConfirm: string()
					.oneOf(
						[ref("newPassword"), null],
						intl.formatMessage(messages.confirmMustBeEqual)
					)
					.required(intl.formatMessage(messages.required))
			}),
		[intl]
	);

	return (
		<Formik
			initialValues={{
				oldPassword: "",
				newPassword: "",
				newPasswordConfirm: ""
			}}
			validationSchema={passwordChangeSchema}
			onSubmit={async (values, form) => {
				const result = await updatePassword(undefined, values);
				form.setSubmitting(false);
				form.setStatus({ result });
			}}
		>
			{({ isValid, isSubmitting, status }) => (
				<Box maxWidth={300} clone>
					<Grid component={Form} container spacing={3}>
						<Grid item xs={12}>
							<Field
								id="profile-old-password"
								name="oldPassword"
								component={TextField}
								type="password"
								autoComplete="current-password"
								label={
									<FormattedMessage
										id="dealer.profile.old-password.label"
										defaultMessage="Old password"
									/>
								}
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Field
								id="profile-new-password"
								name="newPassword"
								component={TextField}
								type="password"
								autoComplete="new-password"
								label={
									<FormattedMessage
										id="dealer.profile.new-password.label"
										defaultMessage="New password"
									/>
								}
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Field
								id="profile-new-password-repeat"
								name="newPasswordConfirm"
								component={TextField}
								type="password"
								autoComplete="new-password"
								label={
									<FormattedMessage
										id="dealer.profile.new-password-repeat.label"
										defaultMessage="Confirm new password"
									/>
								}
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<Box display="flex" alignItems="center">
								<PendingButton
									type="submit"
									disabled={!isValid}
									pending={isSubmitting}
									variant="outlined"
									color="secondary"
								>
									<FormattedMessage
										id="dealer.profile.button.change-password.label"
										defaultMessage="Change password"
									/>
								</PendingButton>
								{status && status.result && status.result.message ? (
									<Box p={1} color="green" display="flex" alignItems="center">
										{status.result.success && <CheckIcon />}
										<Typography
											component="span"
											color={status.result.success ? "inherit" : "error"}
										>
											{status.result.message}
										</Typography>
									</Box>
								) : null}
							</Box>
						</Grid>
					</Grid>
				</Box>
			)}
		</Formik>
	);
};

const RequestNewPassword = () => {
	const requestPasswordChange = useFetcher(
		PasswordResetResource.requestPasswordChange()
	);

	const [changeRequestResponse, setChangeRequestResponse] =
		useState<PasswordResetResource>();

	return (
		<Box p={4} component={Paper}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h4">
						<FormattedMessage
							id="dealer.profile.change-password-header"
							defaultMessage="Password"
						/>
					</Typography>
				</Grid>
				{changeRequestResponse && changeRequestResponse.direct ? (
					<Grid item xs={12}>
						<ChangePasswordForm />
					</Grid>
				) : (
					<>
						<Grid item xs={12}>
							<PendingButton
								variant="outlined"
								color="secondary"
								error={changeRequestResponse && !changeRequestResponse.success}
								onClick={async () => {
									const response = await requestPasswordChange(undefined, {
										channelType: "email"
									});
									setChangeRequestResponse(response);
								}}
							>
								<FormattedMessage
									id="dealer.profile.button.new-password.label"
									defaultMessage="New password"
								/>
							</PendingButton>
							{changeRequestResponse && (
								<Box p={1} color="green" display="flex" alignItems="center">
									{changeRequestResponse.success && <CheckIcon />}
									<Typography
										component="span"
										color={changeRequestResponse.success ? "inherit" : "error"}
									>
										{changeRequestResponse.message}
									</Typography>
								</Box>
							)}
						</Grid>
					</>
				)}
			</Grid>
		</Box>
	);
};

const Profile = ({ className }: { className?: string }) => {
	const [editMode, setEditMode] = useState(false);

	const [profile, setProfile] = useState(
		useResource(ProfileResource.detail(), {})
	);
	const updateProfile = useFetcher(ProfileResource.updateProfile());
	const refetchVulcanData = useFetcher(VulcanDataResource.detail());

	const intl = useIntl();

	const profileUpdateSchema = useMemo(
		() =>
			object({
				lastName: string().required(intl.formatMessage(messages.required)),
				email: string().required(intl.formatMessage(messages.required)),
				mobileNo: string()
					.min(8, intl.formatMessage(messages.mobileValidation))
					.required(intl.formatMessage(messages.required))
			}),
		[intl]
	);

	const thereHasBeenChanges = (formValues: {
		lastName: string;
		email: string;
		mobileNo: string;
	}) =>
		formValues.lastName !== profile.lastName ||
		formValues.email !== profile.email ||
		formValues.mobileNo !== profile.mobileNo;

	return (
		<Box p={4} className={className}>
			<Grid container spacing={3}>
				<Grid item md={4} xs={12}>
					<Box p={4} component={Paper}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Box component={Avatar} height={136} width={136}>
									{profile.initials}
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="h5">{profile.fullName}</Typography>
								<Typography>{profile.roleName}</Typography>
							</Grid>
						</Grid>
					</Box>
				</Grid>

				<Grid item md={8} xs={12}>
					<Formik
						initialValues={{
							lastName: profile.lastName,
							mobileNo: profile.mobileNo,
							email: profile.email
						}}
						validationSchema={profileUpdateSchema}
						onSubmit={async (values, form) => {
							const result = await updateProfile(undefined, values);
							form.setSubmitting(false);
							form.setStatus({ result });
							setEditMode(false);
							if (result.error) {
								setProfile(
									prevProfile =>
										({
											...prevProfile,
											error: result.error
										} as ProfileResource)
								);
							} else {
								setProfile(result);
								//	Update userfullname in left menu nav drawer
								refetchVulcanData({});
							}
						}}
					>
						{({ isValid, isSubmitting, status, values, initialValues }) => (
							<Box p={4} component={Paper}>
								<Grid container spacing={3} component={Form}>
									<Grid item xs={11}>
										<Typography gutterBottom variant="h4">
											<FormattedMessage
												id="dealer.profile.header"
												defaultMessage="Profile"
											/>
										</Typography>
									</Grid>
									<Grid item xs={1}>
										{profile.canUpdateVendorProfile &&
											(editMode ? (
												<IconButton onClick={() => setEditMode(false)}>
													<CloseIcon />
												</IconButton>
											) : (
												<IconButton onClick={() => setEditMode(true)}>
													<EditIcon />
												</IconButton>
											))}
									</Grid>
									<Grid item xs={6}>
										<Typography variant="caption">
											<FormattedMessage
												id="dealer.profile.first-name.label"
												defaultMessage="First name"
											/>
										</Typography>
										<Typography variant="h6">{profile.firstName}</Typography>
									</Grid>
									<Grid item xs={6}>
										{editMode ? (
											<Field
												id="profile-lastName"
												name="lastName"
												component={TextField}
												type="text"
												label={
													<FormattedMessage
														id="dealer.profile.last-name.label"
														defaultMessage="Surname"
													/>
												}
												variant="outlined"
												fullWidth
											/>
										) : (
											<>
												<Typography variant="caption">
													<FormattedMessage
														id="dealer.profile.last-name.label"
														defaultMessage="Surname"
													/>
												</Typography>
												<Typography variant="h6">{profile.lastName}</Typography>
											</>
										)}
									</Grid>
									<Grid item xs={12}>
										{editMode ? (
											<>
												<Field
													id="profile-email"
													name="email"
													component={TextField}
													type="email"
													label={
														<FormattedMessage
															id="dealer.profile.email.label"
															defaultMessage="E-mail"
														/>
													}
													variant="outlined"
													fullWidth
												/>
												<Typography variant="caption">
													{intl.formatMessage(messages.emailWarning)}
												</Typography>
											</>
										) : (
											<>
												<Typography variant="caption">
													<FormattedMessage
														id="dealer.profile.email.label"
														defaultMessage="E-mail"
													/>
												</Typography>
												<Typography variant="h6">{profile.email}</Typography>
											</>
										)}
									</Grid>
									<Grid item xs={12}>
										{editMode ? (
											<Field
												id="profile-mobileNo"
												name="mobileNo"
												component={TextField}
												type="tel"
												label={
													<FormattedMessage
														id="dealer.profile.mobile.label"
														defaultMessage="Mobile number"
													/>
												}
												variant="outlined"
												fullWidth
											/>
										) : (
											<>
												<Typography variant="caption">
													<FormattedMessage
														id="dealer.profile.mobile.label"
														defaultMessage="Mobile number"
													/>
												</Typography>
												<Typography variant="h6">{profile.mobileNo}</Typography>
											</>
										)}
									</Grid>
									{editMode && (
										<Grid item xs={12}>
											<PendingButton
												type="submit"
												disabled={!isValid || !thereHasBeenChanges(values)}
												pending={isSubmitting}
												variant="outlined"
												endIcon={<SaveIcon />}
											>
												<FormattedMessage
													id="dealer.profile.button.update-profile.label"
													defaultMessage="Save changes"
												/>
											</PendingButton>
										</Grid>
									)}
									{profile.error && (
										<Grid item xs={12}>
											<Typography component="span" color="error">
												{profile.error}
											</Typography>
										</Grid>
									)}
								</Grid>
							</Box>
						)}
					</Formik>
				</Grid>

				<Hidden smDown>
					<Grid item md={4} xs="auto" />
				</Hidden>

				<Grid item md={8} xs={12}>
					<RequestNewPassword />
				</Grid>
			</Grid>
		</Box>
	);
};

export default Profile;
