import { useContext, useEffect } from "react";
import {
	IThirdPartyScriptsContext,
	ThirdPartyScriptsContext
} from "./ThirdPartyScriptsContext";

export default function GoogleTagManager({
	GoogleTagManagerCode,
	CustomerCategoryId
}) {
	const thirdPartySettings = useContext(ThirdPartyScriptsContext);

	useEffect(() => {
		initGoogleTagManager(thirdPartySettings.google, GoogleTagManagerCode);
	}, [GoogleTagManagerCode]);

	useEffect(() => {
		gtmManagerIdentity(thirdPartySettings.google, CustomerCategoryId);
	}, [CustomerCategoryId]);

	return null;

	// <noscript> will only have an effect if we do server side rendering
	// (if javascript is disabled react will not run)

	// const noScriptGtm =
	// 	"https://www.googletagmanager.com/ns.html?id=" + GoogleTagManagerCode;

	// return (
	// 	<noscript>
	// 		<iframe
	// 			title="noscriptGtm"
	// 			src={noScriptGtm}
	// 			height="0"
	// 			width="0"
	// 			style={{ display: "none", visibility: "hidden" }}
	// 		/>
	// 	</noscript>
	// );
}

const gtmManagerIdentity = (
	google: IThirdPartyScriptsContext["google"],
	type?: number
) => {
	if (type) {
		//do nothing if type is falsy or 0
		let typeString;

		switch (type) {
			case 1:
				typeString = "bil";
				break;
			case 2:
				typeString = "gjenstand";
				break;
			default:
				break;
		}

		google.push({
			event: "identifisering",
			userType: typeString
		});
	}
};

const initGoogleTagManager = (
	google: IThirdPartyScriptsContext["google"],
	code?: string
) => {
	google.pushAll("init", {
		"gtm.start": new Date().getTime(),
		event: "gtm.js"
	});

	if (google.appendScript) {
		const firstScriptTag = document.getElementsByTagName("script")[0];

		const scriptTag = document.createElement("script");

		scriptTag.async = true;
		scriptTag.src = `https://www.googletagmanager.com/gtm.js?id=${code}`;

		firstScriptTag?.parentNode?.insertBefore(
			scriptTag,
			firstScriptTag.nextSibling
		);
	}
};

export const updateGTMVirtualPageViews = (
	google: IThirdPartyScriptsContext["google"],
	applicationNumber?: string,
	productName?: string,
	vendorName?: string,
	pageIndex?: number,
	isPostProcess?: boolean,
	includeProduct?: boolean,
	includeObjectCategory?: boolean,
	loanAmount?: number,
	objectCategory?: string
) => {
	let virtualPageViewTitle = "";
	let virtualPageViewURL = "";
	let virtualPageViewURLPostfix = GTMCodeFromProductName(
		productName ?? "",
		includeProduct
	);

	if (isPostProcess) {
		virtualPageViewURL = `/bekreftelse${virtualPageViewURLPostfix}`;
		virtualPageViewTitle = "Bekreftelse";
	} else {
		switch (pageIndex) {
			case 0:
				virtualPageViewURL = `/finansiering${virtualPageViewURLPostfix}`;
				virtualPageViewTitle = "Finansiering";
				break;
			case 1:
				virtualPageViewURL = `/gjenstand${virtualPageViewURLPostfix}`;
				virtualPageViewTitle = "Gjenstand";
				break;
			case 2:
				virtualPageViewURL = `/kundeinfo${virtualPageViewURLPostfix}`;
				virtualPageViewTitle = "Kundeinfo";
				break;
			case 3:
				virtualPageViewURL = `/oppsummering${virtualPageViewURLPostfix}`;
				virtualPageViewTitle = "Oppsummering";
				break;
			default:
				break;
		}
	}

	const hotjarUri = `/soknad${virtualPageViewURLPostfix}/steg${pageIndex}`;

	if (
		virtualPageViewTitle &&
		virtualPageViewURL &&
		(!includeObjectCategory || objectCategory)
	) {
		let windowDataLayerURLs: string[] = google
			.getHistory()
			.filter((item: any) => item.event === "VirtualPageview")
			.map((item: any) => item.virtualPageURL);

		// Avoid virtual pageview duplicates
		if (windowDataLayerURLs.some(x => x === virtualPageViewURL)) {
			return;
		}

		google.push({
			event: "VirtualPageview",
			virtualPageURL: virtualPageViewURL,
			virtualPageTitle: virtualPageViewTitle,
			product: productName,
			vendor: vendorName,
			objectCategory: objectCategory,
			loanAmount: loanAmount,
			orderId: isPostProcess ? (applicationNumber?.split("-") ?? [""])[0] : null
		});

		//	Call hj() after pushing to dataLayer
		if (window["hj"]) {
			window["hj"]("vpv", hotjarUri);
		}
	}
};

const GTMCodeFromProductName = (
	productName: string,
	includeProduct?: boolean
) => {
	if (!includeProduct) return "";
	return (
		"-" +
		productName
			.toLowerCase()
			.replace(/ /g, "-")
			.replace(/æ/g, "ae")
			.replace(/ø/g, "oe")
			.replace(/å/g, "aa")
	);
};
