import { useState, useCallback } from "react";
import Grid, { GridProps, GridWrap } from "@material-ui/core/Grid";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import HelpIcon from "@material-ui/icons/Help";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CachedIcon from "@material-ui/icons/Cached";

import { Field as FormikField, useFormikContext } from "formik";

import CalculationField from "./CalculationField";
import {
	mapFieldWidthToGridSize,
	GridJustification,
	TemplateField,
	ErrorDisplay
} from "@ploy-lib/types";
import { makeStyles } from "@material-ui/core/styles";
import { getFieldName } from "@ploy-lib/core";
import clsx from "clsx";
import {
	DployFormControl,
	getFieldError,
	getNoTouchError
} from "@ploy-ui/form-fields";
import {
	useWriteLockedFields,
	useDispatch,
	useGetDebugFieldProps,
	Patch
} from "@ploy-lib/calculation";
import { MarkdownElement } from "@ploy-ui/core";
import { defineMessages } from "react-intl";
import {
	Box,
	Theme,
	useMediaQuery,
	useTheme,
	Divider
} from "@material-ui/core";
import { fieldToKey, isNotNull } from "../utils";

const useStyles = makeStyles(
	(theme: Theme) => ({
		root: {},
		rowContainer: {},
		field: {},
		fieldSeparator: {},
		fieldWrapper: {
			position: "relative"
		},
		alignCenterInParent: {
			alignSelf: "center"
		},
		styleTooltipContainer: {
			display: "flex",
			alignItems: "center"
		},
		styleTooltip: {
			alignItems: "center"
		},
		styleHelperText: {
			// Possible slightly brittle this one ^^'
			marginTop: -3,
			"& p, & ul, & ol": {
				fontSize: "0.85rem"
			},
			"& p": {
				marginTop: -4,
				marginBottom: 12,
				whiteSpace: "pre-line"
			}
		},
		collapsedSectionField: {
			color: theme.palette.text.disabled
		},
		errorContainer: {
			marginTop: -14
		},
		divider: {
			margin: "0px"
		}
	}),
	{ name: "DployFormFieldContainer" }
);

interface FieldContainerProps {
	fields: TemplateField[];
	className?: string;
	literal?: boolean;
	justifyContent?: GridJustification;
	wrap?: GridWrap;
	disabled?: boolean;
	lowContrastText?: boolean;
	linkedHelperText?: boolean;
	desktopViewForMobile?: boolean;
}

function useResetWriteLocked(fields: TemplateField[]) {
	const dispatch = useDispatch();
	const resetFields = useWriteLockedFields(
		...fields.filter(f => f.canResetWriteLocked)
	);

	const resetWriteLocked = useCallback(() => {
		const patches = resetFields
			.filter(isNotNull)
			.filter(calcField => calcField.writeLocked)
			.map((calcField): Patch<string, any> | null =>
				calcField.namespace && calcField.variableName
					? {
							changeTrigger: "CHECK_" + calcField.variableName,
							namespace: calcField.namespace,
							target: calcField.variableName,
							writeLocked: false
					  }
					: null
			)
			.filter(isNotNull);

		dispatch({
			type: "patch",
			payload: { patches }
		});
	}, [resetFields, dispatch]);

	const canReset =
		resetFields
			.filter(isNotNull)
			.filter(x => x.namespace && x.variableName)
			.filter(calcField => calcField.writeLocked).length > 0;

	return canReset ? resetWriteLocked : undefined;
}

const FieldContainer = (props: FieldContainerProps) => {
	const {
		className,
		literal,
		justifyContent,
		wrap,
		fields,
		disabled,
		lowContrastText,
		linkedHelperText = false,
		desktopViewForMobile
	} = props;

	const [first] = fields;
	const { errors, touched } = useFormikContext();

	const variant =
		first.variant && ["outlined", "filled", "standard"].includes(first.variant)
			? (first.variant as "outlined")
			: "outlined";

	const helperText = fields
		.filter(f => f.helperText)
		.filter(f => !(f.literal || literal) || f.showHelperTextIfLiteral)
		.filter(f => !f.disabled)
		.map(f => f.helperText)
		.join("\n");

	const trimmedHelperText =
		helperText[0] === "!" ? helperText.substring(1) : helperText;

	const error = fields
		.map(f =>
			getFieldError(
				errors,
				touched,
				getFieldName(f),
				f.literal || literal
					? f.errorDisplayLiteral ?? ErrorDisplay.Never
					: f.errorDisplay ?? ErrorDisplay.Touched
			)
		)
		.filter(e => e.error);

	const [showHelperText, setShowHelperText] = useState(helperText[0] === "!");

	const toggleHelperText = useCallback(
		() => setShowHelperText(show => !show),
		[]
	);

	const theme = useTheme();

	const mobileMediaQuery = useMediaQuery(theme.breakpoints.down("xs"));
	const isMobile = desktopViewForMobile ? false : mobileMediaQuery;

	const classes = useStyles(props);
	const getDebugFieldProps = useGetDebugFieldProps();

	const resetWriteLocked = useResetWriteLocked(
		literal ? [] : fields.filter(f => !f.literal)
	);

	const resetButton = resetWriteLocked ? (
		<IconButton onClick={resetWriteLocked}>
			<CachedIcon fontSize="small" />
		</IconButton>
	) : undefined;

	const showHelperTextIconButton = (
		<HelperTextIconButton
			showHelperText={showHelperText}
			toggleHelperText={toggleHelperText}
			firstField={first}
		/>
	);

	const leftPartOfMargin = fields[0].margin?.split(" ").slice(-1)[0];

	return (
		<>
			<Grid
				className={clsx(classes.root, className)}
				item
				xs={(literal && trimmedHelperText) || fields[0].fullWidth ? 12 : 11}
			>
				<Grid
					className={classes.rowContainer}
					container
					spacing={1}
					justifyContent={justifyContent}
					wrap={wrap}
				>
					{fields.map((field, idx, arr) => {
						const {
							label: fieldLabel,
							name: fieldName,
							width,
							margin = "normal",
							uppercaseLabel
						} = field;
						let { hiddenLabel } = field;
						const name = getFieldName(field);

						const defaultGridSize = (12 /
							Math.min(4, fields.length)) as GridProps["sm"];

						const lastField = idx === arr.length - 1;

						return (
							<>
								<Grid
									style={{
										margin: field.margin ? field.margin : undefined,
										textDecoration: field.underline ? "underline" : undefined,
										display: "flex"
									}}
									key={fieldToKey(field)}
									item
									xs={
										isMobile
											? 12
											: width
											? mapFieldWidthToGridSize(width)
											: defaultGridSize
									}
									data-cr-field={field.name}
									data-cr-namespace={field.namespace}
									data-cr-component={field.renderAs}
									{...getDebugFieldProps(field, {
										className: clsx(classes.fieldWrapper, classes.field, {
											[classes.fieldSeparator]: !lastField,
											[classes.alignCenterInParent]: field.alignCenterInParent
										})
									})}
								>
									<FormikField
										{...field}
										style={{
											fontSize: field.fontSize ? field.fontSize : undefined,
											height: field.padding ? "auto" : undefined,
											padding: field.padding ? field.padding : undefined
										}}
										errorDisplay={getNoTouchError(
											field.errorDisplay ?? ErrorDisplay.TouchedNoMessage
										)}
										errorDisplayLiteral={getNoTouchError(
											field.errorDisplayLiteral ?? ErrorDisplay.Never
										)}
										name={name}
										component={CalculationField}
										fieldName={fieldName}
										margin={margin}
										className={clsx(classes.field, {
											[classes.collapsedSectionField]: lowContrastText
										})}
										label={
											hiddenLabel
												? " "
												: uppercaseLabel && fieldLabel
												? fieldLabel.toLocaleUpperCase()
												: fieldLabel
										}
										literal={literal || field.literal}
										justifyContent={justifyContent || field.justifyContent}
										helperText={undefined}
										buttonText={fieldLabel}
										disabled={disabled || field.disabled}
									/>
									{resetButton && lastField && (
										<Box position="absolute" top={-5} p={0.5} right={-26}>
											{resetButton}
										</Box>
									)}
								</Grid>
								{field.useFieldSeperator && literal && (
									<Grid item xs={12}>
										<Divider
											className={classes.divider}
											orientation="horizontal"
										/>
									</Grid>
								)}
							</>
						);
					})}
					{linkedHelperText &&
						trimmedHelperText &&
						!literal &&
						showHelperTextIconButton}
				</Grid>
			</Grid>
			{!linkedHelperText &&
				trimmedHelperText &&
				!literal &&
				showHelperTextIconButton}
			{error.length > 0 && (
				<Grid
					item
					xs={12}
					className={classes.errorContainer}
					style={leftPartOfMargin ? { marginLeft: leftPartOfMargin } : {}}
				>
					{error.map((err, i) => (
						<FormHelperText key={i} variant={variant} error>
							{err.helperText}
						</FormHelperText>
					))}
				</Grid>
			)}
			{showHelperText && trimmedHelperText && (
				<Grid
					item
					xs={12}
					style={leftPartOfMargin ? { marginLeft: leftPartOfMargin } : {}}
				>
					<FormHelperTextContainerWrapper
						helperText={trimmedHelperText}
						variant={variant}
					/>
				</Grid>
			)}
		</>
	);
};
export const messages = defineMessages({
	Helper: {
		id: "template-form.field-container.helper",
		description: "Press for help text",
		defaultMessage: "Press for help text"
	}
});

FieldContainer.displayName = "DployFormFieldContainer";

export default FieldContainer;
export { FieldContainer };

interface HelperTextIconButtonProps {
	firstField?: TemplateField;
	toggleHelperText: () => void;
	showHelperText: boolean;
}

export const HelperTextIconButton = (props: HelperTextIconButtonProps) => {
	const { firstField, toggleHelperText, showHelperText } = props;

	const classes = useStyles();

	return (
		<Grid item xs={1} className={classes.styleTooltipContainer}>
			<DployFormControl
				variant={firstField?.variant as any}
				className={classes.styleTooltip}
			>
				{firstField?.label && <FormLabel> </FormLabel>}
				<IconButton onClick={toggleHelperText}>
					<Icon>{showHelperText ? <HelpIcon /> : <HelpOutlineIcon />}</Icon>
				</IconButton>
			</DployFormControl>
		</Grid>
	);
};

interface HelperTextContainerProps {
	helperText: string;
	variant?: "outlined" | "filled" | "standard";
}

export const FormHelperTextContainerWrapper = (
	props: HelperTextContainerProps
) => {
	const { helperText, variant } = props;

	const classes = useStyles();

	return (
		<FormHelperText variant={variant}>
			<div className={classes.styleHelperText}>
				<MarkdownElement text={helperText} />
			</div>
		</FormHelperText>
	);
};
