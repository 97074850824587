import React, {
	Suspense,
	useMemo,
	useState,
	useEffect,
	useCallback
} from "react";
import {
	FormattedRelativeTime,
	FormattedDate,
	FormattedNumber
} from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
	Cells,
	ExpandableTable,
	ExpandedRow,
	ExpandedRowProps,
	ColumnDefinition
} from "@ploy-ui/core";
import {
	LinearProgress,
	Box,
	Typography,
	Paper,
	TablePagination
} from "@material-ui/core";
import { PendingButton } from "@ploy-ui/core";
import ApplicationActions from "./ApplicationActions";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import {
	ApplicationInfoResource,
	ApplicationInfoDetailsResource,
	ApplicationInfoFilter,
	ApplicationInfo
} from "@ploy-lib/rest-resources";

import { useResource, useFetcher, useInvalidator } from "@rest-hooks/core";
import { useNavigate } from "react-router-dom";
import { legacyApiResourceUrl } from "@ploy-lib/core";
import { useFormikContext } from "formik";
import { useDebounce } from "use-debounce";
import { useTenant } from "@ploy-ui/tenants";

const defaultColumnData: ColumnDefinition<
	ApplicationInfoResource,
	ApplicationInfoDetailsResource
>[] = [
	{
		id: "applicationStatusNameExternal",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.status.label"
				description="result table column label for status"
				defaultMessage="Status"
			/>
		),
		component: props => (
			<Cells.ColoredStatus {...props} statusKey="applicationStatusId" />
		),
		hidden: { xsDown: true },
		hiddenInExpandable: { smUp: true }
	},
	{
		id: "applicationNumber",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.applicationNumber.label"
				description="result table column label for applicationNumber"
				defaultMessage="App. nr."
			/>
		),
		hidden: { smDown: true },
		hiddenInExpandable: true
	},
	{
		id: "customerName",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.customerName.label"
				description="result table column label for customer name"
				defaultMessage="Customer"
			/>
		),
		component: props => (
			<Cells.DoubleLineSmDown
				{...props}
				statusKey="applicationStatusId"
				secondLineKey="applicationNumber"
			/>
		),
		hiddenInExpandable: true
	},
	{
		id: "field5",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.object.label"
				description="result table column label for object"
				defaultMessage="Class"
			/>
		),
		hidden: true
	},
	{
		id: "productNameExternal",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.productName.label"
				description="result table column label for product name"
				defaultMessage="Product"
			/>
		),
		hidden: { smDown: true },
		hiddenInExpandable: { mdUp: true }
	},
	{
		id: "field8",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.regNumber.label"
				description="result table column label for object registration number"
				defaultMessage="Registration number"
			/>
		),
		hidden: true,
		hiddenInExpandable: true
	},
	{
		id: "field17",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.vinNumber.label"
				description="result table column label for object vin number"
				defaultMessage="Vin number"
			/>
		),
		hidden: true,
		hiddenInExpandable: true
	},
	{
		id: "field18",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.prodNumber.label"
				description="result table column label for object production number"
				defaultMessage="Production number"
			/>
		),
		hidden: true,
		hiddenInExpandable: true
	},
	{
		id: "internalUser",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.internaluser.label"
				description="result table column label for internal user name"
				defaultMessage="Internal responsible"
			/>
		),
		hidden: true,
		hiddenInExpandable: true,
		expandedComponent: ({ rowData, loadedData }) => {
			return (
				<>
					<span>{rowData.internalUser}</span>
					<br />
					<span>
						<a
							href={
								"mailto:" +
								loadedData?.responsible.email +
								"?subject=" +
								rowData.applicationNumber
							}
						>
							{loadedData?.responsible.email}
						</a>
					</span>
					{loadedData?.responsible.mobile && (
						<>
							<br />
							<span>Tlf: {loadedData?.responsible.mobile}</span>
						</>
					)}
				</>
			);
		}
	},
	{
		id: "backofficeAccountId",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.accountId.label"
				description="result table column label for backoffice account id"
				defaultMessage="Agreement number"
			/>
		),
		hidden: true,
		hiddenInExpandable: (_, { productCodeExternal }) =>
			productCodeExternal !== "LEIE"
	},
	{
		id: "salesPersonName",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.salesPersonName.label"
				description="result table column label for salesPersonName"
				defaultMessage="Salesperson"
			/>
		),
		hidden: { smDown: true },
		hiddenInExpandable: { mdUp: true }
	},
	{
		id: "documentStatusNameExternal",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.documentStatusName.label"
				description="result table column label for document status name external"
				defaultMessage="Doc. status"
			/>
		),
		hiddenInExpandable: true
	},
	{
		id: "createdDate",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.createdDate.label"
				description="result table column label for created date"
				defaultMessage="Delivery date"
			/>
		),
		hidden: true,
		format: val =>
			val && (
				<FormattedDate value={val} day="numeric" month="long" year="numeric" />
			)
	},
	{
		id: "paidDate",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.paidDate.label"
				description="result table column label for paid date"
				defaultMessage="Payment date"
			/>
		),
		hidden: true,
		hiddenInExpandable: val => !val,
		format: val =>
			val && (
				<FormattedDate value={val} day="numeric" month="long" year="numeric" />
			)
	},
	{
		id: "applicationLastChanged",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.applicationLastChanged.label"
				description="result table column label for application last changed"
				defaultMessage="Last change"
			/>
		),
		hidden: true,
		tooltip: val =>
			val && (
				<FormattedDate
					value={val}
					day="numeric"
					month="long"
					year="numeric"
					hour="numeric"
					minute="numeric"
				/>
			),
		format: val =>
			val && (
				<FormattedMessage
					id="dealer.allapplications.result.column.applicationLastChanged.value"
					defaultMessage={"{relative}"}
					values={{
						relative: (
							<FormattedRelativeTime
								value={(new Date(val).getTime() - new Date().getTime()) / 1000}
								unit="second"
								updateIntervalInSeconds={10}
							/>
						),
						date: (
							<FormattedDate
								value={val}
								day="numeric"
								month="long"
								year="numeric"
								hour="numeric"
								minute="numeric"
							/>
						)
					}}
				/>
			)
	},
	{
		id: "loanAmount",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.loanAmount.label"
				description="result table column label for loan amount"
				defaultMessage="Loan amount"
			/>
		),
		hidden: true,
		format: val => val && <FormattedNumber value={val} format="currency" />
	},
	{
		loadedId: "responsible.mobile",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.responsible.mobile.label"
				description="result table column label for responsible mobile"
				defaultMessage="Phone coordinator"
			/>
		),
		hidden: true,
		hiddenInExpandable: (_, __, details) =>
			details?.responsible.mobile !== "undefined"
	},
	{
		loadedId: "responsible.email",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.responsible.email.label"
				description="result table column label for responsible email"
				defaultMessage="Email coordinator"
			/>
		),
		hidden: true,
		component: props => (
			<Cells.MailtoLink {...props} subject={row => row.applicationNumber} />
		),
		hiddenInExpandable: (_, __, details) =>
			details?.responsible.email !== "undefined"
	},
	{
		id: "actions",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.actions.label"
				description="result table column label for actions"
				defaultMessage="Actions"
			/>
		),
		hidden: { mdDown: true },
		hiddenInExpandable: { mdUp: true },
		component: props => <ApplicationActions {...props} />
	},
	{
		id: "invoiceNoRef",
		label: (
			<FormattedMessage
				id="dealer.allapplications.result.column.invoiceNoRef.label"
				description="reference number for application"
				defaultMessage="Reference Number"
			/>
		),
		hidden: true,
		hiddenInExpandable: true
	}
];

export const messages = defineMessages({
	lblCancelWarningApplication: {
		id: "dealer.allapplications.searchresults.cancelWarningApplication",
		description: "Label for cancel application alert",
		defaultMessage: "Are you sure you want to cancel case: {applicationNumber}"
	}
});

const useStyles = makeStyles(theme => ({
	applicationStatusNameExternal: {
		width: 160,
		textAlign: "center"
	},
	applicationNumber: {
		width: 100
	},
	customerNameInner: {
		display: "flex",
		alignItems: "center",
		"& > div": {
			marginRight: 10
		}
	},
	customerNameText: {
		fontWeight: "bold"
	},
	cancelApplication: {
		float: "right"
	},
	actions: {
		padding: 0
	},
	noResultPaper: {
		width: "100%",
		padding: "18.5px 14px",
		marginBottom: "10%"
	},
	noResultText: {
		textAlign: "center",
		fontWeight: 700
	},
	cellPadding: {
		padding: `${theme.spacing(2)}px ${theme.spacing(0.5)}px`
	}
}));

const SearchResults = ({ columnData = defaultColumnData }) => {
	const classes = useStyles();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(20);
	const tenantConfig = useTenant();
	const columnSettings = tenantConfig?.tenant?.settings?.allApplicationsColumns;

	const { values } = useFormikContext<ApplicationInfoFilter>();

	const [searchParams] = useDebounce(values, 200, { leading: true });

	const params = useMemo(
		() => ({
			...searchParams,
			page: page + 1,
			pageSize: rowsPerPage
		}),
		[page, rowsPerPage, searchParams]
	);

	const {
		records,
		recordCount,
		pageSize = rowsPerPage
	} = useResource(ApplicationInfoResource.list(), params);

	const invalidator = useInvalidator(ApplicationInfoResource.list());

	useEffect(
		() => () => {
			invalidator(params);
		},
		[invalidator, params]
	);

	const individualAppInvalidator = useInvalidator(
		ApplicationInfoResource.detail()
	);
	const individualAppFetcher = useFetcher(ApplicationInfoResource.detail());

	const individualAppDetailInvalidator = useInvalidator(
		ApplicationInfoDetailsResource.detail()
	);
	const individualAppDetailsFetcher = useFetcher(
		ApplicationInfoDetailsResource.detail()
	);

	const reloadApp = useCallback(
		(app: ApplicationInfo) => {
			individualAppInvalidator({ applicationNumber: app.applicationNumber });
			individualAppDetailInvalidator({ appNr: app.applicationNumber });
			individualAppFetcher(
				{ applicationNumber: app.applicationNumber },
				undefined
			);
			individualAppDetailsFetcher({ appNr: app.applicationNumber }, undefined);
		},
		[
			individualAppDetailInvalidator,
			individualAppDetailsFetcher,
			individualAppFetcher,
			individualAppInvalidator
		]
	);

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const columns = useMemo(
		() =>
			columnData.map(c => {
				let { id, hidden, hiddenInExpandable } = c;

				let colSetting = columnSettings?.find(x => x.id === id);

				let reloadData: ((row: ApplicationInfo) => void) | undefined =
					undefined;

				if (colSetting) {
					hidden = colSetting.hidden ?? hidden;
					hiddenInExpandable =
						colSetting.hiddenInExpandable ?? hiddenInExpandable;
				}

				const showAll = searchParams.salepersonId === undefined;

				switch (c.id) {
					case "actions":
						if (showAll) {
							hidden = true;
							hiddenInExpandable = undefined;
						}
						reloadData = reloadApp;
						break;
					case "salesPersonName":
						if (!showAll) {
							hidden = true;
							hiddenInExpandable = undefined;
						}
						break;
					default:
						break;
				}

				return {
					...c,
					className: clsx(
						classes[c.id as keyof typeof classes],
						classes.cellPadding
					),
					hidden,
					hiddenInExpandable,
					props: reloadData != null ? { ...c.props, reloadData } : c.props
				};
			}),
		[columnData, columnSettings, searchParams.salepersonId, classes, reloadApp]
	);

	const navigate = useNavigate();

	return recordCount > 0 ? (
		<>
			<ExpandableTable
				tableType="applicationListContainer"
				idColumn="applicationId"
				tableData={records}
				columnData={columns}
				onRowClick={row => navigate(row.applicationNumber)}
				renderExpandedSection={row => {
					return (
						<Suspense fallback={<LinearProgress />}>
							<SearchResultExpandedRow
								rowData={row}
								columnData={columns}
								searchParams={params}
							/>
						</Suspense>
					);
				}}
			/>
			<TablePagination
				rowsPerPageOptions={[10, 20, 50]}
				component="div"
				count={recordCount}
				rowsPerPage={pageSize}
				page={page}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</>
	) : (
		<Paper className={classes.noResultPaper}>
			<Typography className={classes.noResultText}>
				<FormattedMessage
					id="dealer.allapplications.searchresults.noresult"
					description="Label for no result from searchresult"
					defaultMessage="No results"
				/>
			</Typography>
		</Paper>
	);
};

function SearchResultExpandedRow(
	props: {
		searchParams: {};
	} & ExpandedRowProps<ApplicationInfoResource, ApplicationInfoDetailsResource>
) {
	const { rowData, searchParams } = props;

	const loadedData = useResource(ApplicationInfoDetailsResource.detail(), {
		appNr: rowData.applicationNumber
	});

	const refresh = useFetcher(ApplicationInfoResource.list());
	const refreshDetails = useFetcher(ApplicationInfoDetailsResource.detail());

	return (
		<>
			<ExpandedRow loadedData={loadedData} {...props} />

			{loadedData.canCancelApp && (
				<CancelButton
					applicationId={rowData.applicationId}
					applicationNumber={rowData.applicationNumber}
					onSuccess={() => {
						refresh(searchParams);
						refreshDetails({
							appNr: rowData.applicationNumber
						});
					}}
				/>
			)}
		</>
	);
}

function CancelButton(
	props: Pick<
		ApplicationInfoResource,
		"applicationId" | "applicationNumber"
	> & { onSuccess?: () => void }
) {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState<unknown>();

	const { applicationId, applicationNumber, onSuccess } = props;

	const intl = useIntl();

	const cancel = async () => {
		setLoading(true);

		const payload = {
			applicationId
		};

		try {
			if (
				window.confirm(
					intl.formatMessage(messages.lblCancelWarningApplication, {
						applicationNumber
					})
				)
			) {
				const res = await fetch(
					legacyApiResourceUrl("AllApplications/CancelApplication"),
					{
						method: "POST",
						body: new Blob([JSON.stringify(payload)], {
							type: "application/json"
						}),
						headers: {
							accept: "application/json"
						}
					}
				);

				if (!res.ok) {
					throw new Error("Failed to cancel application");
				}

				setSuccess(true);

				if (onSuccess) onSuccess();
			}
		} catch (error) {
			setError(error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box display="flex" justifyContent="flex-end">
			{error instanceof Error ? (
				<Typography color="error">{error.message}</Typography>
			) : null}
			<PendingButton
				color="secondary"
				variant="contained"
				onClick={cancel}
				pending={loading}
				success={success}
				error={!!error}
			>
				<FormattedMessage
					id="dealer.allapplications.searchresults.cancelapplication"
					description="Label for cancel application button"
					defaultMessage="Cancel application"
				/>
			</PendingButton>
		</Box>
	);
}

export default SearchResults;
