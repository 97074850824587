import {
	useCallback,
	useRef,
	useEffect,
	useReducer,
	useDebugValue
} from "react";
import { useService } from "@ploy-lib/calculation";
import { FieldProps as FormikFieldProps } from "formik";
import { FieldService } from "@ploy-lib/types";
import { idle } from "../utils";
import { ServiceResult } from "@ploy-lib/calculation";
import { useIsMountedRef } from "./useIsMountedRef";

export const useServiceHandler = (
	fieldService?: FieldService,
	field?: FormikFieldProps["field"],
	namespace?: string
) => {
	const [{ pending, result }, dispatch] = useReducer(
		(
			state: { pending: boolean; result?: ServiceResult; error?: string },
			action:
				| { type: "trigger" }
				| { type: "success"; payload: ServiceResult }
				| { type: "error"; payload: string }
		) => {
			switch (action.type) {
				case "trigger":
					return { ...state, pending: true };
				case "success":
					return { result: action.payload, pending: false };
				case "error":
					return {
						...state,
						result: { data: { error: action.payload } } as ServiceResult,
						pending: false
					};
			}

			return state;
		},
		{
			pending: false
		}
	);

	const serviceName = fieldService && fieldService.service;
	const serviceNamespace =
		(fieldService && fieldService.namespace) || namespace;

	const service = useService(serviceNamespace, serviceName);

	const serviceRef = useRef(service);
	const fieldValueRef = useRef(field && field.value);

	useEffect(() => {
		serviceRef.current = service;
		fieldValueRef.current = field && field.value;
	});

	const isMountedRef = useIsMountedRef();

	const handler = useCallback(
		async (
            e?: React.MouseEvent<Element>,
			search?: string,
			params?: { [key: string]: string }
		): Promise<ServiceResult> => {
			dispatch({ type: "trigger" });

			// Workaround to call the service with the result of potential context changes.
			await idle({ timeout: 1000 });

			const data = search || fieldValueRef.current;
			const payload = data && { data };

			const result = await serviceRef.current(params, payload);

			if (isMountedRef.current) {
				if (result.timeout)
					dispatch({ type: "error", payload: "Request timed out" });
				if (result.data && result.data.error)
					dispatch({ type: "error", payload: result.data.error });
				else if (!result.cancelled)
					dispatch({ type: "success", payload: result });
			}

			return result;
		},
		[isMountedRef]
	);

	useDebugValue(serviceName);

	return [fieldService ? handler : undefined, pending, result] as const;
};
