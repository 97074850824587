import { useTenant } from "../context";

export function useTenantImage(type: string, index = 0, wrapIndex = false) {
	const { tenant, brand } = useTenant();

	const tenantImages = tenant?.images?.[type] ?? [];
	const brandImages = brand?.images?.[type] ?? [];

	const images = brandImages.length > 0 ? brandImages : tenantImages;

	const idx = wrapIndex ? index % images.length : index;

	if (idx > images.length) {
		console.warn(`Image of type: "${type}", at index ${idx} not found.`);
		return null;
	}

	const src = images[idx];

	return src;
}

export function useTenantHeaderIcons() {
	const { tenant } = useTenant();

	const formIcons = tenant?.formIcons;

	return formIcons || ([] as { Name: string; Icon: string }[]);
}
