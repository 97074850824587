/*
	GoogleTagManager-handler for page change, to update Virtual Page Views for Brage
*/

import { LoginResource, VulcanDataResource } from "@ploy-lib/rest-resources";
import { useCache, useResource } from "@rest-hooks/core";
import { useAppLoad } from "./appLoadContext";
import {
	ThirdPartyScriptsContext,
	updateGTMVirtualPageViews,
	updateGTMVirtualPageViewsExtended
} from "@ploy-ui/core";
import { useVariableData } from "@ploy-lib/calculation";
import { useCallback, useContext, useEffect } from "react";

export const useHandlePageChangeForGTM = (
	startIndex: number,
	step: number,
	totalSteps: number,
	tabName?: string,
	fromSubmit?: boolean
) => {
	const appLoadData = useAppLoad();

	const { isAuthenticated } = useCache(LoginResource.status(), {}) ?? {};

	const vulcanData = useResource(
		VulcanDataResource.detail(),
		isAuthenticated ? {} : null
	);

	const thirdPartySettings = useContext(ThirdPartyScriptsContext);

	const loanAmount = useVariableData<number>("Calculator", "LoanAmount");

	type optionsType = { key: string; value: string };
	const objectCategoriesOptions = useVariableData<optionsType[]>(
		"Object",
		"options_CategoryCombined"
	);
	const objectCategoryId = useVariableData<string>(
		"Object",
		"CategoryCombined"
	);

	//	Matches e.g. '"85|48"' to '{key: "85|48", value: "Campingvogn|Kjøretøy"}' => "Campingvogn":
	const selectedObjectCategory: string | undefined =
		objectCategoriesOptions?.value
			?.filter((catCombined: optionsType) => {
				return (
					catCombined["key"].split("|")[0] ===
					objectCategoryId.value?.split("|")[0]
				);
			})[0]
			?.value.split("|")[0];

	const updateGtm = useCallback(
		(pageIndex: number, tabName?: string) => {
			if (thirdPartySettings.settings.loadGoogleTagManager) {
				if (thirdPartySettings.settings.loadGoogleTagManagerExtended) {
					updateGTMVirtualPageViewsExtended(
						thirdPartySettings.google,
						appLoadData.applicationNumber,
						appLoadData.productName,
						vulcanData?.vendorName,
						tabName,
						startIndex,
						pageIndex,
						totalSteps,
						appLoadData.vulcanContext === "PostProcess",
						thirdPartySettings.settings.googleTagManagerIncludeProduct,
						thirdPartySettings.settings.googleTagManagerIncludeObjectCategory,
						loanAmount.value ?? 0,
						selectedObjectCategory,
						appLoadData.productId,
						appLoadData.vendorInfo?.salesChannel,
						fromSubmit
					);
				} else {
					updateGTMVirtualPageViews(
						thirdPartySettings.google,
						appLoadData.applicationNumber,
						appLoadData.productName,
						vulcanData?.vendorName,
						pageIndex,
						appLoadData.vulcanContext === "PostProcess",
						thirdPartySettings.settings.googleTagManagerIncludeProduct,
						thirdPartySettings.settings.googleTagManagerIncludeObjectCategory,
						loanAmount.value ?? 0,
						selectedObjectCategory
					);
				}
			}
		},
		[
			appLoadData.applicationNumber,
			appLoadData.productName,
			appLoadData.vulcanContext,
			appLoadData.productId,
			appLoadData.vendorInfo?.salesChannel,
			vulcanData?.vendorName,
			loanAmount.value,
			selectedObjectCategory,
			startIndex,
			totalSteps
		]
	);

	useEffect(
		() => thirdPartySettings.settings && updateGtm(step, tabName),
		[objectCategoryId.value, step, tabName]
	);
};
