import React, { useMemo } from "react";
import {
	NavigationContextValue,
	NavigationProvider
} from "@ploy-ui/template-form";
import { generatePath, useNavigate } from "react-router-dom";
import { createSearchParams } from "@ploy-lib/routing";

export interface RouteNavigationProviderProps {
	children: React.ReactNode;
	home?: string;
	application?: string;
	applicationFromCalculator?: string;
}

export function RouteNavigationProvider(props: RouteNavigationProviderProps) {
	const {
		children,
		home = "/",
		application = "application/:applicationNumber",
		applicationFromCalculator = ":category/:externalCode"
	} = props;

	const navigate = useNavigate();

	const value: NavigationContextValue = useMemo(
		() => ({
			home: () => navigate({ pathname: home }),
			application: (
				applicationNumber,
				fromSubmit: boolean = false,
				customerContext: string = ""
			) =>
				navigate({
					pathname: generatePath(application, { applicationNumber }),
					search: createSearchParams({
						fromSubmit: fromSubmit ? fromSubmit : undefined
					})
				}),
			applicationFromCalculator: (category, externalCode) =>
				navigate({
					pathname: generatePath(applicationFromCalculator, {
						category,
						externalCode
					})
				})
		}),
		[application, applicationFromCalculator, home, navigate]
	);

	return <NavigationProvider value={value}>{children}</NavigationProvider>;
}
