import {
	ApplicationSignerResource,
	SigningDocumentResource,
	useInvalidateOnUnmount
} from "@ploy-lib/rest-resources";
import { ApplicationStatus } from "@ploy-lib/types";
import { useAppLoad } from "../appLoadContext";
import { useCalculationFields, Field } from "@ploy-lib/calculation";
import { useStatefulResource } from "@rest-hooks/legacy";

export const useSignerSetup = ({ skip }: { skip?: boolean } = {}) => {
	const { applicationNumber, isDirectUser, settings, applicationStatus } =
		useAppLoad();

	const [regNumber] = useCalculationFields({
		name: "RegNumber",
		namespace: "Object"
	} as Field);

	const { requireRegnrForEsign, signersMustHaveContactInfo } =
		settings || false;

	var missingRequiredRegnrForEsign =
		requireRegnrForEsign &&
		regNumber &&
		(regNumber.fieldValue === null || regNumber.fieldValue === "");

	const approvedStatuses = [
		ApplicationStatus.AutoApproved,
		ApplicationStatus.ApprovedWithConditions,
		ApplicationStatus.ManuallyApproved
	];
	const canLoadResources = !!(
		!skip &&
		!isDirectUser &&
		applicationNumber &&
		approvedStatuses.includes(applicationStatus)
	);

	const signersParams = canLoadResources
		? {
				signerType: 0,
				applicationNumber
		  }
		: null;
	const {
		data: signers = [],
		loading: signersLoading,
		error: signersError
	} = useStatefulResource(ApplicationSignerResource.list(), signersParams);
	useInvalidateOnUnmount(ApplicationSignerResource.list(), signersParams);

	const vendorSignersParams = canLoadResources
		? {
				signerType: 3,
				applicationNumber
		  }
		: null;
	const {
		data: vendorSigners = [],
		loading: vendorSignersLoading,
		error: vendorSignersError
	} = useStatefulResource(
		ApplicationSignerResource.list(),
		vendorSignersParams
	);
	useInvalidateOnUnmount(ApplicationSignerResource.list(), vendorSignersParams);

	const documentsParams = canLoadResources
		? {
				applicationNumber
		  }
		: null;
	const {
		data: documents = [],
		loading: documentsLoading,
		error: documentsError
	} = useStatefulResource(SigningDocumentResource.list(), documentsParams);
	useInvalidateOnUnmount(SigningDocumentResource.list(), documentsParams);

	const signerSatisfiesRequirements = (s: ApplicationSignerResource) =>
		s.mustSign && (s.phone || s.email);

	const incompleteCustomerSignerSetup =
		documentsLoading ||
		signersLoading ||
		documentsError != null ||
		signersError != null ||
		(canLoadResources &&
			documents.some(d => d.signerTypes.some(s => s === 0 || s === 1)) &&
			(signersMustHaveContactInfo
				? !signers.every(signerSatisfiesRequirements)
				: !signers.some(signerSatisfiesRequirements)));

	const incompleteVendorSignerSetup =
		documentsLoading ||
		vendorSignersLoading ||
		documentsError != null ||
		vendorSignersError != null ||
		(canLoadResources &&
			documents.some(d => d.signerTypes.some(s => s === 3)) && // ref SignerTypeEnum
			!vendorSigners.some(signerSatisfiesRequirements));

	const allDocumentsSigned =
		!documentsLoading &&
		documentsError == null &&
		canLoadResources &&
		documents.every(d => d.read || d.signed);

	return {
		loading: signersLoading || vendorSignersLoading || documentsLoading,
		error: signersError ?? vendorSignersError ?? documentsError,
		incompleteCustomerSignerSetup,
		incompleteVendorSignerSetup,
		incompleteSignerSetup:
			incompleteCustomerSignerSetup || incompleteVendorSignerSetup,
		missingRequiredRegnrForEsign,
		allDocumentsSigned,
		errors: signers.map(signer => signer.dataValidationErrorMessage)
	};
};
