import React, { memo, Suspense } from "react";
import { SectionProps } from "../Section";
import { makeStyles } from "@material-ui/core/styles";
import { addRegisteredSectionLayout } from "../sectionLayoutDescriptions";
import { FormattedMessage, FormattedNumber } from "react-intl";
import clsx from "clsx";

import {
	CircularProgress,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Theme,
	Typography
} from "@material-ui/core";
import { useResource } from "@rest-hooks/core";
import { PriceOverviewResource, IPriceTableRow } from "./PriceOverviewResource";
import { useCalculationFields } from "@ploy-lib/calculation";

const useStyles = makeStyles(
	(theme: Theme) => ({
		table: {
			paddingLeft: 20,
			paddingRight: 20
		},
		title: {
			fontSize: "2.375rem",
			fontWeight: 700,
			lineHeight: 1.32,
			letterSpacing: "-.03rem",
			textAlign: "left"
		},
		currentPriceRow: { fontWeight: "bold" },
		header: { fontWeight: "bold" },
		transparent: { backgroundColor: "transparent" },
		tableCell: {
			padding: 5,
			borderBottom: "none"
		},
		tableHeader: {
			padding: 5,
			borderBottom: "none"
		},
		text: {
			textAlign: "left"
		}
	}),
	{ name: "DployPriceOverviewSection" }
);

addRegisteredSectionLayout({
	name: "PriceOverviewSection",
	displayName: "PriceOverviewSection",
	settings: {}
});

const PriceOverviewSection = (props: SectionProps) => {
	return (
		<div style={{ textAlign: "center" }}>
			<Suspense fallback={<CircularProgress />}>
				<PriceOverviewSectionImpl {...props} />
			</Suspense>
		</div>
	);
};

const PriceOverviewSectionImpl = memo((props: SectionProps) => {
	const fields = [
		{
			namespace: "Calculator",
			name: "ActualInterestRate"
		},
		{
			namespace: "Calculator",
			name: "TotalPayableAmount"
		},
		{
			namespace: "Calculator",
			name: "LoanAmount"
		}
	];

	const calculationFields = useCalculationFields(...fields);
	const actualInterest = Number(calculationFields[0]?.value ?? 0) / 100;
	const totalPrice = Number(calculationFields[1]?.value ?? 0);
	const creditCost = totalPrice - Number(calculationFields[2]?.value ?? 0);

	const { priceExample, tableRows } = useResource(
		PriceOverviewResource.detail(),
		{ totalPrice: totalPrice }
	);

	const classes = useStyles();

	return (
		<Grid container spacing={5}>
			<Grid item xs={1} />
			<Grid item xs={10}>
				<Typography variant="h3" className={classes.title}>
					<FormattedMessage
						id="ploy-ui.template-form.PriceOverviewSection.title"
						defaultMessage="Price Overiew"
						description="Price overview section title"
					/>
				</Typography>
			</Grid>
			<Grid item xs={1} />
			<PriceOverviewTable
				tableRows={tableRows}
				actualInterest={actualInterest}
			/>
			<Grid item xs={1} />
			<Grid item xs={10}>
				<Typography variant="h3" className={classes.title}>
					<FormattedMessage
						id="ploy-ui.template-form.PriceOverviewSection.subtitle"
						defaultMessage="Price Example"
						description="Price example title"
					/>
				</Typography>
			</Grid>
			<Grid item xs={1} />
			<Grid item xs={1} />
			<Grid item xs={10}>
				<Typography className={classes.text}>
					<FormattedMessage
						id="ploy-ui.template-form.PriceOverviewSection.price_example"
						defaultMessage="{carPrice}, over {terms} years, nominell interest {nominalInterest}, actual interest {actualInterest}, cost {cost} total {total}."
						description="Price example"
						values={{
							terms: priceExample?.termsInYears,
							carPrice: (
								<FormattedNumber
									value={priceExample?.carPrice || 0}
									format="currency"
								/>
							),
							nominalInterest: (
								<FormattedNumber
									minimumFractionDigits={2}
									maximumFractionDigits={2}
									style="percent"
									value={(priceExample?.nominalInterest ?? 0) / 100}
								/>
							),
							actualInterest: (
								<FormattedNumber
									minimumFractionDigits={2}
									maximumFractionDigits={2}
									style="percent"
									value={actualInterest}
								/>
							),
							cost: <FormattedNumber value={creditCost} format="currency" />,
							total: <FormattedNumber value={totalPrice} format="currency" />
						}}
					/>
				</Typography>
			</Grid>
			<Grid xs={1} />
		</Grid>
	);
});

const PriceOverviewTable = ({
	tableRows,
	actualInterest
}: {
	tableRows: IPriceTableRow[] | undefined;
	actualInterest: number;
}) => {
	const classes = useStyles();

	return (
		<>
			<Grid item xs={1} />
			<Grid item xs={10}>
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell
									className={clsx(classes.transparent, classes.tableHeader)}
								>
									<Typography className={classes.header}>
										<FormattedMessage
											id="ploy-ui.template-form.PriceOverviewSection.table.header.equity"
											defaultMessage="Equity"
											description="Header equity column"
										/>
									</Typography>
								</TableCell>
								<TableCell
									className={clsx(classes.transparent, classes.tableHeader)}
								>
									<Typography className={classes.header}>
										<FormattedMessage
											id="ploy-ui.template-form.PriceOverviewSection.table.header.nominal_interest"
											defaultMessage="Nominal interest"
											description="Header nominal interest column"
										/>
									</Typography>
								</TableCell>
								<TableCell
									className={clsx(classes.transparent, classes.tableHeader)}
								>
									<Typography className={classes.header}>
										<FormattedMessage
											id="ploy-ui.template-form.PriceOverviewSection.table.header.actual_interest"
											defaultMessage="Actual interest"
											description="Header actual interest column"
										/>
									</Typography>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody className={classes.transparent}>
							{tableRows?.map((row, i) => (
								<TableRow key={i}>
									<TableCell
										className={clsx(classes.tableCell, {
											[classes.currentPriceRow]: row.currentPriceRow
										})}
										component="th"
										scope="row"
									>
										{row.lowEquity === 0 ? (
											<FormattedMessage
												id="ploy-ui.template-form.PriceOverviewSection.table.header.equity-low"
												defaultMessage="Under {highEquity} %"
												description="Equity below high value"
												values={{ highEquity: row.highEquity }}
											/>
										) : row.highEquity === 100 ? (
											<FormattedMessage
												id="ploy-ui.template-form.PriceOverviewSection.table.header.equity-high"
												defaultMessage="Over {lowEquity} %"
												description="Equity above low value"
												values={{ lowEquity: row.lowEquity }}
											/>
										) : (
											<FormattedMessage
												id="ploy-ui.template-form.PriceOverviewSection.table.header.equity-from-to"
												defaultMessage="{lowEquity} - {highEquity} %"
												description="Equity between low and high values"
												values={{
													lowEquity: row.lowEquity,
													highEquity: row.highEquity
												}}
											/>
										)}
									</TableCell>
									<TableCell
										className={clsx(classes.tableCell, {
											[classes.currentPriceRow]: row.currentPriceRow
										})}
									>
										{row.nominalInterest > 0 ? (
											<FormattedNumber
												minimumFractionDigits={2}
												maximumFractionDigits={2}
												style="percent"
												value={row.nominalInterest / 100}
											/>
										) : (
											"-"
										)}
									</TableCell>
									<TableCell
										className={clsx(classes.tableCell, {
											[classes.currentPriceRow]: row.currentPriceRow
										})}
									>
										{row.currentPriceRow ? (
											<FormattedNumber
												minimumFractionDigits={2}
												maximumFractionDigits={2}
												style="percent"
												value={actualInterest}
											/>
										) : (
											"-"
										)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<Grid item xs={1} />
		</>
	);
};
PriceOverviewSection.displayName = "DployPriceOverviewSection";

export { PriceOverviewSection };
