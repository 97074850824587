import React, { memo, useMemo } from "react";

import { Formik, Form, FormikHelpers, Field } from "formik";
import { object, string, date } from "yup";

import {
	Grid,
	FormControl,
	FormHelperText,
	CircularProgress,
	Button
} from "@material-ui/core";

import {
	UppercaseTextField,
	KeyboardDatePickerField,
	useDateUtils,
	SelectField
} from "@ploy-ui/form-fields";
import { KeyValuePair } from "@ploy-lib/km-reading-api";

export interface SearchFormProps {
	onSearch(
		values: SearchFormValues,
		formik: FormikHelpers<SearchFormValues>
	): void;
	vendorSearch(searchString: string): Promise<KeyValuePair<string>[]>;
	isInternal: boolean;
	hideDateInput: boolean;
}

export interface SearchFormValues {
	carRegistrationNo: string;
	fromDate: string;
	toDate: string;
	vendor: string;
}

export const SearchForm = memo(
	({ onSearch, isInternal, vendorSearch, hideDateInput }: SearchFormProps) => {
		const utils = useDateUtils();
		const today = utils.date();

		const SearchSchema = useMemo(
			() =>
				object().shape({
					carRegistrationNo: string().matches(
						/^[A-Z]{2}\d{4,5}$/i,
						"Invalid format"
					),
					fromDate: date().nullable(true).notRequired(),
					toDate: date().nullable(true).notRequired(),
					vendor: isInternal
						? string().required("Må legge inn en forhandler")
						: object().nullable(true).notRequired()
				}),
			[isInternal]
		);

		return (
			<Formik
				initialValues={{
					carRegistrationNo: "",
					fromDate: utils
						.startOfMonth(utils.setMonth(today, utils.getMonth(today)))!
						.toISODate(),
					toDate: utils
						.startOfMonth(utils.setMonth(today, utils.getMonth(today) + 3))!
						.toISODate(),
					vendor: ""
				}}
				onSubmit={onSearch}
				validationSchema={SearchSchema}
				validateOnChange={false}
			>
				{({ isSubmitting, status = {} }) => (
					<Form>
						<Grid container spacing={2} alignItems="flex-start">
							{isInternal && (
								<Grid item xs={12} lg={3}>
									<Field
										items={[]}
										name="vendor"
										component={SelectField}
										getItemSuggestions={async x => {
											if (x.length > 2) return await vendorSearch(x);
											return [];
										}}
										getItemLabel={item => item.key}
										getItemValue={item => item.value}
										searchable
										label="Forhandler"
										variant="outlined"
										margin="normal"
										helperText=" "
										disabled={isSubmitting}
										fullWidth
									/>
								</Grid>
							)}
							{!hideDateInput && (
								<Grid item xs={12} md={6} lg="auto">
									<Field
										name="fromDate"
										label="Fra"
										component={KeyboardDatePickerField}
										disabled={isSubmitting}
										margin="normal"
										variant="outlined"
										pickerVariant="inline"
										helperText=" "
										fullWidth
										autoOk
										allowKeyboardControl
										format="long"
										allowNull
									/>
								</Grid>
							)}
							{!hideDateInput && (
								<Grid item xs={12} md={6} lg="auto">
									<Field
										name="toDate"
										label="Til"
										component={KeyboardDatePickerField}
										disabled={isSubmitting}
										margin="normal"
										variant="outlined"
										pickerVariant="inline"
										helperText=" "
										fullWidth
										autoOk
										allowKeyboardControl
										format="long"
										allowNull
									/>
								</Grid>
							)}
							<Grid item xs={12} md={8} lg="auto">
								<Field
									name="carRegistrationNo"
									label="Registreringsnummer"
									variant="outlined"
									component={UppercaseTextField}
									disabled={isSubmitting}
									margin="normal"
									helperText=" "
									fullWidth
									InputLabelProps={{ shrink: true }}
								/>
							</Grid>
							<Grid item xs={12} md={4} lg={2}>
								<FormControl variant="standard" margin="normal" fullWidth>
									<Button
										type="submit"
										variant="contained"
										color="secondary"
										size="large"
										disabled={isSubmitting}
										fullWidth
									>
										{isSubmitting ? <CircularProgress size={20} /> : "Søk"}
									</Button>
									<FormHelperText
										color={
											status.success && !status.error ? "inherit" : "error"
										}
									>
										{status.error || status.message || " "}
									</FormHelperText>
								</FormControl>
							</Grid>
						</Grid>
					</Form>
				)}
			</Formik>
		);
	}
);
