import { apiResourceUrl } from "@ploy-lib/core";
import paramsToString, { BaseCamelCasedResource } from "./BaseResource";

export interface BeneficialOwnerV2 {
	readonly beneficialOwnerId: string;
	readonly customerId: number | null;
	readonly entNr: string | null;
	readonly firstname: string | null;
	readonly lastname: string | null;
	readonly name: string | null;
	readonly address: string | null;
	readonly addressCity: string | null;
	readonly addressZip: string | null;
	readonly share: string | null;
	readonly isActualBeneficialOwner: boolean;
	readonly addUser: string | null;
	readonly addDate: string | null;
	readonly updatedDate: string | undefined;
	readonly foreignOwner: boolean;
}
export class BeneficialOwnerResourceV2
	extends BaseCamelCasedResource
	implements BeneficialOwnerV2
{
	readonly beneficialOwnerId: string = "";
	readonly customerId: number | null;
	readonly entNr: string | null;
	readonly firstname: string | null;
	readonly lastname: string | null;
	readonly name: string | null;
	readonly address: string | null;
	readonly addressCity: string | null;
	readonly addressZip: string | null;
	readonly share: string | null;
	readonly isActualBeneficialOwner: boolean = false;
	readonly addUser: string | null;
	readonly addDate: string | null;
	readonly updatedDate: string | undefined;
	readonly foreignOwner: boolean;

	pk() {
		return this.beneficialOwnerId;
	}

	static listUrl(
		allParams: Readonly<Record<string, string | number>> = {}
	): string {
		const { customerId, applicationId, customerRole } = allParams;

		if (customerRole !== undefined) {
			return `${this.urlRoot}/${applicationId}/${customerRole}`;
		}

		if (customerId !== undefined) {
			return `${this.urlRoot}/${customerId}`;
		}
		throw new Error("BeneficialOwnerResourceV2 requires customerId");
	}

	static urlRoot = apiResourceUrl("beneficial-owner-v2/");
}
