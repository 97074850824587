import { LimitDetail, LimitDetailEntity } from "@ploy-lib/rest-resources";
import * as Yup from "yup";
import { FieldType, TableColumnMetadata } from "./types";

export class ApplicationCustomersSchema {
	static dployTesterContext: string = "ApplicaitonCustomers";
	static isReadOnlyRow = (rowData: any, cells: any) =>
		rowData?.engagementCustomerId &&
		rowData.engagementCustomerId !== rowData.customerId;
	static metaData: Partial<Record<keyof any, TableColumnMetadata>> = {
		phoneMobile: {
			fieldType: FieldType.Text,
			isRequired: true
		},
		customerNumber: {
			fieldType: FieldType.Text,
			isRequired: true
		},
		customerCompanyNumber: {
			fieldType: FieldType.Text,
			isRequired: true
		}
	};
}
