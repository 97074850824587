import { Endpoint } from "@rest-hooks/endpoint";
import { BaseResource } from "./BaseResource";

export interface AdditionalTerm {
	guid?: string;
	term: string;
	appliesTo?: string;
	addedAutomatically?: boolean;
	readOnly?: boolean;
}

export class AdditionalTermsResource
	extends BaseResource
	implements AdditionalTerm {
	readonly guid?: string = undefined;
	readonly term: string = "";
	readonly appliesTo?: string = undefined;
	readonly addedAutomatically?: boolean = undefined;
	readonly readOnly?: boolean = undefined;

	pk(parent?: any, key?: string): string {
		return this.guid ?? "";
	}

	static urlRoot = "/api/applications/{applicationNumber}/additional-terms";
}

export interface AdditionalTermSettings {
	appliesToList: { optionPairId: number; text: string; value: string }[];
	termsAreLocked?: boolean;
	hideAvailableAdditionalTermsOnLock?: boolean;
}

const fetchAdditionalTermSettings = (data: {
	applicationNumber: string;
}): Promise<AdditionalTermSettings> =>
	fetch(
		`/api/applications/${data.applicationNumber}/additional-terms/settings`
	).then(res => {
		if (res.ok) return res.json();
		return [];
	});

export const AdditionalTermSettingsEndpoint = new Endpoint(
	fetchAdditionalTermSettings,
	{}
);
