import React, { memo, useState, Suspense, useCallback, useEffect } from "react";
import {
	Tab,
	Tabs,
	makeStyles,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
	CircularProgress,
	useTheme,
	useMediaQuery,
	Grid,
	LinearProgress,
	Box,
	Icon
} from "@material-ui/core";
import { PaperProps } from "@material-ui/core/Paper";
import { GridJustification, GridProps, GridWrap } from "@material-ui/core/Grid";
import clsx from "clsx";
import { useGetDebugSectionProps } from "@ploy-lib/calculation";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Section, customSections, SectionProps } from "../section";
import { TemplatePanel, TemplateSection } from "@ploy-lib/types";
import {
	useTemplateSectionIsVisible,
	useTemplateFieldIsVisible,
	useSectionHeader
} from "../hooks";
import { useFormState } from "..";
import { MarkdownElement } from "@ploy-ui/core";
import { getIn, useFormikContext } from "formik";
import { getRegisteredSectionLayouts } from "../section/sectionLayoutDescriptions";

export interface PanelProps extends TemplatePanel {
	justifyContent?: GridJustification;
	wrap?: GridWrap;
	disableExpansion?: boolean;
	disableTabs?: boolean;
	transparent?: boolean;
	fullWidth?: boolean;
	className?: string;
	paperComponent?: React.ElementType<PaperProps>;
	maxWidth?: number;
	minHeight?: number;
}

const usePanelStyles = makeStyles(
	theme => ({
		root: {},
		sectionGroupsContainer: {
			width: "100%",
			minHeight: (props: PanelProps) => props.minHeight
		},
		tabsIndicator: {
			backgroundColor: "inherit"
		},
		tabsElevation: (props: PanelProps) => ({
			boxShadow: props.elevation ? theme.shadows[props.elevation] : "none"
		}),
		tabsRoundedTop: {
			borderTopLeftRadius: theme.shape.borderRadius,
			borderTopRightRadius: theme.shape.borderRadius
		},
		tabRoot: {
			fontWeight: 700,
			backgroundColor: theme.palette.common.white,
			"&:not($tabSelected)": {
				backgroundColor: theme.palette.grey[300]
			}
		},
		tabSelected: {}
	}),
	{ name: "DployFormPanel" }
);

const PanelImpl = (props: PanelProps) => {
	const {
		sections = [],
		initialTab,
		square,
		disableTabs,
		hideForMobile,
		...restProps
	} = props;

	const theme = useTheme();
	const mobileMediaQuery = useMediaQuery(theme.breakpoints.down("xs"));
	const isMobile = props.desktopViewForMobile ? false : mobileMediaQuery;

	const isSectionVisible = useTemplateSectionIsVisible();

	const panelClasses = usePanelStyles(props);

	const visibleSections = sections
		.filter(isSectionVisible)
		.reduce((sectionsSoFar, section) => {
			// Handle merging of sections
			if (sectionsSoFar.length === 0 || !section.mergeSectionWithPrevious) {
				sectionsSoFar.push(section);
				return sectionsSoFar;
			}

			sectionsSoFar[sectionsSoFar.length - 1] = {
				...sectionsSoFar[sectionsSoFar.length - 1],
				fields: [
					...sectionsSoFar[sectionsSoFar.length - 1].fields,
					...section.fields
				]
			};

			return sectionsSoFar;
		}, [] as TemplateSection[]);

	const groupedSections = visibleSections.reduce((acc, s) => {
		let group: TemplateSection[] | undefined;

		if (s.group) {
			group = acc.find(g => g[0].group === s.group);
		} else if (acc.length > 0 && !acc[acc.length - 1][0].group) {
			group = acc[acc.length - 1];
		}

		if (group) group.push(s);
		else acc.push([s]);

		return acc;
	}, [] as TemplateSection[][]);

	const firstTab = visibleSections.find(s => !!s.tabGroup);

	const [tab, setTab] = useState(initialTab || (firstTab && firstTab.tabGroup));

	const visibleTabs = new Set(
		visibleSections.map(s => s.tabGroup).filter(s => !!s)
	);
	const enableTabs = visibleTabs.size > 1 && !disableTabs;

	if (visibleSections.length <= 0 || (isMobile && hideForMobile)) return null;

	return (
		<div className={panelClasses.root}>
			<Grid container className={clsx(panelClasses.sectionGroupsContainer)}>
				{/* Not tabbed sections */}
				{groupedSections
					.map(g => (enableTabs ? g.filter(s => !s.tabGroup) : g))
					.filter(g => g.length > 0)
					.map((g, idx) => (
						<SectionGroup
							square={square}
							key={idx}
							group={g}
							enableTabs={enableTabs}
							{...restProps}
						/>
					))}
			</Grid>

			{/* Tabbed sections */}
			{enableTabs && (
				<div className={clsx(panelClasses.sectionGroupsContainer)}>
					<Tabs
						variant="fullWidth"
						value={tab}
						onChange={(_, value) => setTab(value)}
						classes={{
							indicator: panelClasses.tabsIndicator
						}}
						className={clsx(panelClasses.tabsElevation, {
							[panelClasses.tabsRoundedTop]: !square
						})}
					>
						{[...visibleTabs].map(label => (
							<Tab
								label={label}
								key={label}
								classes={{
									selected: panelClasses.tabSelected,
									root: panelClasses.tabRoot
								}}
								value={label}
							/>
						))}
					</Tabs>
				</div>
			)}
			{enableTabs && (
				<Grid container className={clsx(panelClasses.sectionGroupsContainer)}>
					{/* Tab content */}
					{enableTabs &&
						groupedSections
							.map(g => g.filter(s => s.tabGroup === tab))
							.filter(g => g.length > 0)
							.map((g, idx) => (
								<SectionGroup
									key={idx.toString()}
									group={g}
									enableTabs={enableTabs}
									{...restProps}
								/>
							))}
				</Grid>
			)}
		</div>
	);
};

interface SectionGroupProps extends Omit<PanelProps, "sections"> {
	group: TemplateSection[];
	enableTabs: boolean;
}

const useSectionGroupStyles = makeStyles(
	theme => ({
		expansionContent: {
			flexDirection: "column",
			justifyContent: "center"
		},
		expansionDetails: {},
		expansionLabel: {},
		expansionLabelUppercase: {
			textTransform: "uppercase"
		},
		expansionLabelBold: {
			fontWeight: "bold"
		},
		expansionSummary: {
			paddingTop: 0,
			paddingBottom: 0,
			display: "flex",
			minHeight: theme.spacing(8),
			height: "auto"
		},
		expansionSummaryCollapsed: {
			"&> div:last-child": {
				alignSelf: "flex-start",
				marginTop: "8px"
			}
		},
		fullWidth: {
			maxWidth: "none",
			margin: 0
		},
		groupBorder: {
			border: "1px solid black",
			borderRadius: "4px"
		},
		groupIconContainer: {
			minWidth: theme.spacing(7)
		},
		groupIcon: {
			textAlign: "center",
			overflow: "visible",
			width: "1.25em"
		},
		hasTabs: {
			"&:first-child": {
				borderTopLeftRadius: 0,
				borderTopRightRadius: 0
			}
		},
		sectionGroup: (props: SectionGroupProps) => ({
			width: "100%",
			maxWidth: props.maxWidth ? props.maxWidth : 550,
			margin: "auto"
		}),
		sectionGroupWrapper: {
			padding: theme.spacing(1),
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(4)
		},
		statusIcon: {
			marginRight: 8
		},
		transparent: {
			backgroundColor: "inherit",
			border: "none"
		},
		groupBackgroundColor: (props: SectionGroupProps) => ({
			backgroundColor:
				theme.palette?.[props.group[0].groupBackgroundColor ?? ""]?.main ??
				props.group[0].groupBackgroundColor,
			marginBottom: 24
		}),
		MuiAccordionRoot: {
			"&:before": {
				display: "none"
			}
		}
	}),
	{ name: "DployFormSectionGroup" }
);
const SectionGroup = (props: SectionGroupProps) => {
	const {
		group,
		enableTabs,
		className,
		elevation,
		square,
		transparent,
		disableExpansion,
		...restProps
	} = props;
	const sectionGroupClasses = useSectionGroupStyles(props);
	const isFieldVisible = useTemplateFieldIsVisible();
	const [firstSection = null] = group;
	const [expanded, setExpanded] = useState(
		firstSection?.expandInitially ?? false
	);

	const { errors, touched } = useFormikContext();
	const hasErrors =
		firstSection?.fields.some(f => {
			const err = getIn(errors, f.namespace + "." + f.name);

			return err &&
				typeof err !== "boolean" &&
				getIn(touched, f.namespace + "." + f.name)
				? err
				: undefined;
		}) ?? false;
	useEffect(() => {
		if (hasErrors) setExpanded(true);
	}, [hasErrors]);

	const getDebugSectionProps = useGetDebugSectionProps();
	const [sectionCompleted, setCompleted] = useState<Record<string, boolean>>(
		{}
	);
	const setSectionCompleted = useCallback(
		(id: string, completed: boolean) =>
			setCompleted(completedSections => ({
				...completedSections,
				[id]: completed
			})),
		[]
	);

	const header =
		useSectionHeader(firstSection) ??
		firstSection?.fields
			.filter(isFieldVisible)
			.map(x => x.label)
			.filter(x => x)
			.join("/");

	if (!firstSection) return null;

	const modifiedGroup = group.map(s =>
		s.displaySectionTitle ? s : { ...s, sectionTitle: undefined }
	);

	const panelClassName = clsx(className, {
		[sectionGroupClasses.transparent]: transparent,
		[sectionGroupClasses.hasTabs]: enableTabs,
		[sectionGroupClasses.groupBorder]: firstSection.groupBorder,
		[sectionGroupClasses.groupBackgroundColor]:
			firstSection.groupBackgroundColor
	});

	const gridComponentProps: Pick<GridProps, "item" | "xs" | "sm"> = {
		item: true,
		xs: 12,
		sm: firstSection.outerWidthSM || 12
	};

	if (
		!firstSection.group ||
		disableExpansion ||
		!firstSection.enableExpansion
	) {
		let restSections = group;
		let seactionAsSummary: undefined | React.ReactNode = undefined;
		if (
			firstSection.layout &&
			getRegisteredSectionLayouts()[firstSection.layout].settings
				.forceRenderAsHeaderWithoutGroup
		) {
			restSections = group.slice(1);
			seactionAsSummary = (
				<MockAccordionSummary
					className={clsx(
						sectionGroupClasses.expansionSummary,
						sectionGroupClasses.sectionGroup
					)}
				>
					<Grid container>
						<InnerSection
							s={firstSection}
							{...getDebugSectionProps(firstSection, {
								...restProps,
								lastSection: group.length === 1
							})}
						/>
					</Grid>
				</MockAccordionSummary>
			);
		}

		return (
			<Accordion
				className={panelClassName}
				expanded={firstSection.expandInitially || false}
				elevation={transparent ? 0 : elevation}
				square={square}
				classes={{
					root:
						Number(elevation) > 0 ? "" : sectionGroupClasses.MuiAccordionRoot
				}}
				component={Grid}
				{...gridComponentProps}
			>
				{/* We use a fragment here to _force_ the following contents to be visible as if it was AccordionSummary, see Material UI's Accordion class.
					Accordion _assumes_ that a AccordionSummary is the _first_ child to render.
					We do not make use of AccordionSummary here because it would introduse expand/close semantics. */}
				<>
					{seactionAsSummary}
					{restSections.length > 0 ? (
						<AccordionDetails
							className={clsx(
								sectionGroupClasses.expansionDetails,
								sectionGroupClasses.expansionContent,
								sectionGroupClasses.sectionGroupWrapper
							)}
						>
							<Grid container>
								{restSections.map((s, i) => (
									<InnerSection
										s={s}
										key={s.formTemplateSectionId ?? i.toString()}
										{...getDebugSectionProps(s, {
											...restProps,
											lastSection: i === restSections.length - 1
										})}
									/>
								))}
							</Grid>
						</AccordionDetails>
					) : null}
				</>
			</Accordion>
		);
	}

	const collapsedGroupSections = modifiedGroup.filter(g => g.showWhenCollapsed);
	const expandedGroupSections = modifiedGroup.filter(g => !g.showWhenCollapsed);

	return (
		<Suspense
			key={firstSection.formTemplateSectionId}
			fallback={
				<Accordion
					elevation={transparent ? 0 : elevation}
					square={square}
					className={panelClassName}
				>
					<AccordionSummary
						className={clsx(
							sectionGroupClasses.expansionSummary,
							sectionGroupClasses.sectionGroup
						)}
						expandIcon={<CircularProgress />}
					>
						<Typography
							className={clsx(sectionGroupClasses.expansionLabel, {
								[sectionGroupClasses.expansionLabelUppercase]:
									firstSection.expansionTitleUppercase,
								[sectionGroupClasses.expansionLabelBold]:
									firstSection.boldExpansionTitle
							})}
						>
							{firstSection?.displaySectionTitle ? header : undefined}
						</Typography>
					</AccordionSummary>
				</Accordion>
			}
		>
			<Accordion
				expanded={expanded}
				onChange={() => setExpanded(!expanded)}
				elevation={transparent ? 0 : elevation}
				square={square}
				className={panelClassName}
				component={Grid}
				data-cr-field={
					disableExpansion
						? "Group"
						: expanded
						? "ExpandedGroup"
						: "ClosedGroup"
				}
				{...gridComponentProps}
			>
				<AccordionSummary
					className={clsx(
						sectionGroupClasses.expansionSummary,
						sectionGroupClasses.sectionGroup,
						{
							[sectionGroupClasses.expansionSummaryCollapsed]: !expanded
						}
					)}
					expandIcon={<ExpandMoreIcon />}
				>
					<Grid container spacing={2}>
						<Grid container item alignItems="center" wrap="nowrap">
							{firstSection.showStatus && (
								<Grid item className={sectionGroupClasses.statusIcon}>
									<Box
										borderRadius="100px"
										p={0.5}
										textAlign="center"
										bgcolor={
											sectionCompleted[firstSection.formTemplateSectionId]
												? "green"
												: "darkgrey"
										}
									/>
								</Grid>
							)}
							{firstSection.groupIcon && (
								<Grid item className={sectionGroupClasses.groupIconContainer}>
									<Icon
										fontSize={"small"}
										className={`fa fa-${firstSection.groupIcon}`}
										classes={{ root: sectionGroupClasses.groupIcon }}
									/>
								</Grid>
							)}
							<Grid item>
								<Typography
									className={clsx({
										[sectionGroupClasses.expansionLabelUppercase]:
											firstSection.expansionTitleUppercase,
										[sectionGroupClasses.expansionLabelBold]:
											firstSection.boldExpansionTitle
									})}
								>
									{firstSection?.displaySectionTitle ? (
										<MarkdownElement>{header}</MarkdownElement>
									) : undefined}
								</Typography>
							</Grid>
						</Grid>
						{collapsedGroupSections.length > 0 && !expanded && (
							<Grid container item xs={12}>
								{collapsedGroupSections.map((s, i) => (
									<InnerSection
										setSectionCompleted={setSectionCompleted}
										s={s}
										collapsedSection
										key={s.formTemplateSectionId || i.toString()}
										{...getDebugSectionProps(s, {
											...restProps,
											lastSection: i === collapsedGroupSections.length - 1
										})}
									/>
								))}
							</Grid>
						)}
					</Grid>
				</AccordionSummary>
				<AccordionDetails
					className={clsx(
						sectionGroupClasses.expansionDetails,
						sectionGroupClasses.expansionContent,
						sectionGroupClasses.sectionGroupWrapper
					)}
				>
					<Grid container>
						{expandedGroupSections.map((s, i) => (
							<InnerSection
								setSectionCompleted={setSectionCompleted}
								s={s}
								key={s.formTemplateSectionId || i.toString()}
								{...getDebugSectionProps(s, {
									...restProps,
									lastSection: i === expandedGroupSections.length - 1
								})}
							/>
						))}
					</Grid>
				</AccordionDetails>
			</Accordion>
		</Suspense>
	);
};

interface InnerSectionProps extends Omit<PanelProps, "sections"> {
	setSectionCompleted?: (id: string, completed: boolean) => void;
	s: TemplateSection;
	collapsedSection?: boolean;
	classes?: any;
	lastSection?: boolean;
}

const useInnerSectionStyles = makeStyles(
	theme => ({
		innerSection: {
			width: "100%",
			maxWidth: ({ fullWidth, maxWidth, s }: InnerSectionProps) =>
				s.fullWidth || (s.fullWidth === undefined && fullWidth)
					? "none"
					: maxWidth
					? maxWidth
					: 550,
			margin: ({ fullWidth, lastSection = false, s }: InnerSectionProps) =>
				s.fullWidth || (s.fullWidth === undefined && fullWidth)
					? 0
					: lastSection
					? "auto"
					: `auto auto ${theme.spacing(s.marginBottom ?? 4)}px auto` //s.marginBottom can be set to 0
		},
		section: {}
	}),
	{ name: "DployFormPanelInnerSection" }
);

const InnerSection = (props: InnerSectionProps) => {
	const {
		s,
		classes,
		literal,
		justifyContent,
		className,
		setSectionCompleted,
		collapsedSection,
		wrap,
		desktopViewForMobile,
		...sectionProps
	} = props;
	const innerSectionClasses = useInnerSectionStyles(props);
	const isFieldVisible = useTemplateFieldIsVisible();
	const SectionComponent: React.ComponentType<SectionProps> =
		(s.layout && customSections[s.layout]) || Section;

	const { formIsLiteral, formIsDisabled } = useFormState();
	//
	return (
		<Suspense fallback={<LinearProgress />}>
			<Grid item xs={12} sm={s.innerWidthSM || 12}>
				<SectionComponent
					className={clsx(innerSectionClasses.innerSection, className)}
					classes={classes}
					{...sectionProps}
					{...s}
					fields={s.fields.filter(isFieldVisible)}
					tableColumns={s.tableColumns}
					allFields={s.fields}
					literal={literal || s.literal || formIsLiteral}
					disabled={formIsDisabled}
					collapsedSection={collapsedSection}
					justifyContent={justifyContent}
					wrap={wrap}
					setSectionCompleted={setSectionCompleted}
					desktopViewForMobile={desktopViewForMobile}
				/>
			</Grid>
		</Suspense>
	);
};

PanelImpl.displayName = "DployFormPanel";

export default memo(PanelImpl);

const useMockAccordionSummaryStyles = makeStyles(
	theme => ({
		root: {
			display: "flex",
			minHeight: 8 * 6,
			padding: theme.spacing(0, 2)
		},
		content: {
			display: "flex",
			flexGrow: 1,
			margin: "12px 0"
		}
	}),
	{
		name: "DployMockAccordionSummary"
	}
);

/**
 * Same formatting as AccordionSummary without the button semantics
 * @param props
 * @returns
 */
function MockAccordionSummary(props: {
	className?: string;
	children?: React.ReactNode;
}) {
	const classes = useMockAccordionSummaryStyles();

	const { children, className } = props;

	return (
		<div className={clsx(classes.root, className)}>
			<div className={classes.content}>{children}</div>
		</div>
	);
}
