import { ID } from "../types";
import { apiResourceUrl } from "@ploy-lib/core";
import { ImageResource } from "./ImageResource";
import { BaseResource } from "./BaseResource";
import { ThemeResource } from "./ThemeResource";
import { DployBrandTheme, DployStyle } from "@ploy-lib/types";
import { schema } from "@rest-hooks/rest";

export class StyleResource extends BaseResource implements DployStyle {
	readonly id?: ID;
	readonly name: string = "";
	readonly description: string = "";
	readonly theme: DployBrandTheme = ThemeResource.fromJS({});
	readonly images: Record<string, ImageResource[]> = {};

	pk() {
		return this.id?.toString();
	}

	static schema = {
		theme: ThemeResource,
		images: new schema.Values([ImageResource])
	};

	static urlRoot = apiResourceUrl("styles");
}
