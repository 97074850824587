import { ID } from "../types";
import { apiResourceUrl } from "@ploy-lib/core";
import { BaseResource } from "./BaseResource";

export interface Competition {
	id: ID;
	name: string;
	description: string;
	group: string;
	visible: boolean;
	current: boolean;
	future: boolean;
	past: boolean;
}

export class CompetitionResource extends BaseResource implements Competition {
	readonly id: ID;
	readonly name: string;
	readonly description: string;
	readonly group: string;
	readonly visible: boolean;
	readonly current: boolean;
	readonly future: boolean;
	readonly past: boolean;
	readonly starttime?: string;
	readonly endtime?: string;

	pk() {
		return this.id.toString();
	}

	static urlRoot = apiResourceUrl("competitions");
}

export interface CompetitionResult {
	position: number;
	competitionId: ID;
	salespersonName: string;
	vendor: string;
	contracts: number;
	loanAmount: number;
	score: number;
}

export class CompetitionResultResource
	extends BaseResource
	implements CompetitionResult
{
	readonly position: number;
	readonly competitionId: ID;
	readonly salespersonName: string;
	readonly vendor: string;
	readonly contracts: number;
	readonly loanAmount: number;
	readonly score: number;

	pk() {
		return `${this.competitionId}/results/${this.position}`;
	}

	static urlRoot = apiResourceUrl("competitions/{competitionId}/results");
}
