import { useDebugValue } from "react";
import { useSignerSetup } from ".";
import { useAppLoad } from "../appLoadContext";
import {
	ApplicationStatusesResource,
	useInvalidateOnUnmount
} from "@ploy-lib/rest-resources";
import { useStatefulResource } from "@rest-hooks/legacy";

const fieldDisabledSignerSetupProp: {
	[key: string]: (s: ReturnType<typeof useSignerSetup>) => boolean | undefined;
} = {
	SendToEsign: s =>
		s.incompleteSignerSetup ||
		s.allDocumentsSigned ||
		s.missingRequiredRegnrForEsign
			? true
			: undefined,
	SendToScreenSign: s =>
		s.incompleteSignerSetup ||
		s.allDocumentsSigned ||
		s.missingRequiredRegnrForEsign
			? true
			: undefined,
	ReleaseProofOfDelivery: s => (s.incompleteSignerSetup ? true : undefined)
};

const fieldDisabledStatusProp: {
	[key: string]: (s: ApplicationStatusesResource) => boolean | undefined;
} = {
	RegNumber: s =>
		s.canEditRegNumber == null ? undefined : !s.canEditRegNumber,
	ChassisNumber: s =>
		s.canEditChassisNumber == null ? undefined : !s.canEditChassisNumber,
	InvoiceNoRef: s =>
		s.canEditInvoiceNoRef == null ? undefined : !s.canEditInvoiceNoRef,
	DueDay: s => (s.canEditDueDay == null ? undefined : !s.canEditDueDay),
	DeliveryDate: s =>
		s.canEditDeliveryDate == null ? undefined : !s.canEditDeliveryDate,
	FormalityDeliveryDate: s =>
		s.canEditDeliveryDate == null ? undefined : !s.canEditDeliveryDate
};

export function useDisabledOverride(name: string | null) {
	const setup = useSignerSetup({
		skip: name == null || !(name in fieldDisabledSignerSetupProp)
	});

	const { applicationNumber, vulcanContext, id } = useAppLoad();

	const appStatusParams =
		name != null &&
		applicationNumber &&
		vulcanContext === "PostProcess" &&
		name in fieldDisabledStatusProp
			? {
					id: applicationNumber,
					__session: id
			  }
			: null;
	const {
		data: appStatus,
		loading: appStatusLoading,
		error: appStatusError
	} = useStatefulResource(
		ApplicationStatusesResource.detail(),
		appStatusParams
	);
	useInvalidateOnUnmount(ApplicationStatusesResource.detail(), appStatusParams);

	let disabled: boolean | undefined = undefined;

	if (setup && name && name in fieldDisabledSignerSetupProp) {
		disabled ||= fieldDisabledSignerSetupProp[name](setup);
	}

	if (appStatus && name && name in fieldDisabledStatusProp) {
		disabled ||= fieldDisabledStatusProp[name](appStatus);
	}

	useDebugValue(disabled);

	const loading = setup.loading || appStatusLoading;

	return {
		disabled: loading ? true : disabled,
		loading,
		error: setup.error ?? appStatusError
	};
}
