import { apiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "@ploy-lib/rest-resources";
import { FetchOptions } from "@rest-hooks/core/*";

export interface IPriceinformation {
	carPrice: number;
	termsInYears: number;
	nominalInterest: number;
}
export interface IPriceTableRow {
	lowEquity: number;
	highEquity: number;
	nominalInterest: number;
	currentPriceRow: boolean;
}
export interface IPriceOverview {
	priceExample?: IPriceinformation;
	tableRows?: IPriceTableRow[];
}

export class PriceOverviewResource
	extends BaseCamelCasedResource
	implements IPriceOverview
{
	readonly priceExample?: IPriceinformation | undefined;
	readonly tableRows?: IPriceTableRow[] | undefined;

	pk() {
		return "singleton";
	}
	static url(urlParams: Readonly<Record<string, any>>): string {
		return this.listUrl(urlParams);
	}

	static urlRoot = apiResourceUrl("priceOverview");

	static getEndpointExtra(): FetchOptions {
		return {
			...super.getEndpointExtra(),
			dataExpiryLength: 0
			// invalidIfStale: true
		};
	}
}
