import {
	Suspense,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { CalculationForm } from "@ploy-ui/calculation-form";
import {
	ProductResource,
	ProductFilter,
	ProductCategory,
	MinimalInitialData,
	ProductSettingsResource
} from "@ploy-lib/rest-resources";
import { NetworkErrorBoundary } from "rest-hooks";
import { useResource } from "@rest-hooks/core";
import { ProductSelector, ProductSelectorVariant } from "./ProductSelector";
import { renderTemplateWithRouteState } from "./renderTemplate";
import { useParsedQueryAndParams, useAbsolutePath } from "@ploy-lib/routing";
import {
	MissingPage,
	useNavigationState,
	useAppActionState,
	AppActionProvider
} from "@ploy-ui/template-form";
import { validApplicationNumber } from "../../utils";
import isEqual from "lodash/isEqual";
import { useInRouterContext, useLocation, useNavigate } from "react-router-dom";
import { NetworkError } from "@ploy-lib/types";
import { Typography } from "@material-ui/core";

export interface ProductViewProps {
	externalCode?: string;
	category?: ProductCategory;
	enableSelect?: boolean;
	formContext?: string;
	context?: string;
	initialize?: boolean;
	wholesale?: boolean;
	onSubmit?: (values: any, form: any) => void;
	disallowedFieldRoles?: readonly string[];
	onSubmitResult?: (submitResult: any) => void;
	newapplication?: boolean;
	externalPostback?: (result: any) => void;
	hideHeader?: boolean;
}

export const ProductView = (props: ProductViewProps) => {
	const { externalPostback, ...rest } = props;

	return (
		<AppActionProvider externalPostback={externalPostback}>
			<ProductViewInternal {...rest} />
		</AppActionProvider>
	);
};

export const ProductViewInternal = (props: ProductViewProps) => {
	const { onSubmit, disallowedFieldRoles } = props;

	const {
		externalCode,
		category,
		enableSelect,
		formContext,
		context,
		wholesale,
		initialize = true,
		ext: initialDataFromQuerystring,
		newapplication,
		hideHeader
	} = useParsedQueryAndParams<{ ext?: MinimalInitialData } & ProductViewProps>(
		props
	);

	const [initialData, setInitialData] = useState(initialDataFromQuerystring);
	useEffect(() => {
		if (!isEqual(initialData, initialDataFromQuerystring))
			setInitialData(initialDataFromQuerystring);
	}, [initialData, initialDataFromQuerystring]);

	const hasRouteState = useInRouterContext();

	const navigation = useNavigationState();
	const appAction = useAppActionState();

	const productFilter: ProductFilter = useMemo(() => {
		const filter: ProductFilter = {
			active: true,
			creatable: true,
			category,
			wholesale,
			newapplication
		};

		if (formContext?.toLowerCase() === "calculator") {
			filter.calculator = true;
		}

		return filter;
	}, [category, wholesale, formContext, newapplication]);

	const productSettings = useResource(ProductSettingsResource.detail(), {});

	const filter = productSettings.useDefaultPhoenixDropdownProductSelectorMenu
		? productFilter
		: externalCode
		? null
		: productFilter;

	const products = useResource(ProductResource.list(), filter) || [];

	const [firstProduct] = products;
	const product = useResource(
		ProductResource.detail(),
		externalCode ? { externalCode: externalCode } : null
	);

	const navigate = useNavigate();
	const { search } = useLocation();
	const searchRef = useRef(search);
	useEffect(() => {
		searchRef.current = search;
	}, [search]);

	useEffect(() => {
		if (!externalCode) {
			const firstExternalCode = firstProduct?.externalCode.toLowerCase();

			if (
				firstExternalCode &&
				productSettings.useDefaultPhoenixDropdownProductSelectorMenu
			)
				navigate(
					{
						pathname: firstExternalCode,
						search: searchRef.current
					},
					{ replace: true }
				);
		}
	}, [
		product,
		firstProduct,
		enableSelect,
		navigate,
		productSettings.useDefaultPhoenixDropdownProductSelectorMenu,
		externalCode
	]);

	if (!product && (!enableSelect || !firstProduct)) {
		throw new NetworkError({ status: 404 } as Response);
	}

	const handleSubmit = useCallback(
		(values, form) => {
			const { __calculation: { submitResult } = {} as any } = values;

			if (submitResult?.DoPostBack && appAction.externalPostback) {
				appAction.externalPostback(submitResult);
			} else {
				if (
					submitResult &&
					validApplicationNumber(submitResult.ApplicationNumber)
				) {
					navigation.application(submitResult.ApplicationNumber, true);
				} else if (product && productFilter.calculator) {
					//navigate from calculator to application
					navigation.applicationFromCalculator(
						product.category.toLocaleLowerCase(),
						product.externalCode.toLocaleLowerCase()
					);
				}
			}
			form.setSubmitting(false);
		},
		[appAction, navigation, product, productFilter.calculator]
	);

	// Make sure routing in ProductSelector is relative to the product route
	// Need to force this here in case there are other nested routes where the header is mounted.
	// Route wizard state is an example of such a case.
	const basepath = useAbsolutePath("..");

	const header = hideHeader
		? undefined
		: product &&
		  (productSettings.useDefaultPhoenixDropdownProductSelectorMenu ? (
				<ProductSelector
					products={products}
					selected={product}
					disabled={!enableSelect}
					basepath={`${basepath}/`}
				/>
		  ) : (
				<Typography variant="h5">{product.name}</Typography>
		  ));

	const productExternalCode =
		(externalCode || product?.externalCode)?.toLocaleUpperCase() || "";

	return (
		<Suspense fallback={<MissingPage loading header={header} />}>
			<NetworkErrorBoundary
				key={productExternalCode}
				fallbackComponent={({ error }) => (
					<MissingPage error={error} header={header} />
				)}
			>
				{!productSettings.useDefaultPhoenixDropdownProductSelectorMenu &&
				!product ? (
					<ProductSelector
						products={products}
						basepath={`${basepath}/`}
						variant={ProductSelectorVariant.Buttons}
					/>
				) : (
					<CalculationForm
						productExternalCode={productExternalCode}
						onSubmit={onSubmit || handleSubmit}
						header={header}
						formContext={formContext}
						context={context}
						skipInitialize={!initialize}
						initialData={initialData}
						disallowedFieldRoles={disallowedFieldRoles}
					>
						{hasRouteState ? renderTemplateWithRouteState : undefined}
					</CalculationForm>
				)}
			</NetworkErrorBoundary>
		</Suspense>
	);
};
