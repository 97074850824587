import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { AppSignatureInfo } from "./AppSignatureInfo";
import { AppSignerList } from "./AppSignerList";
import { FormattedMessage } from "react-intl";
import { AppSigningStatus } from ".";
import { EquityStatus } from "./EquityStatus";
import { TemplateSection, EsignType } from "@ploy-lib/types";
import {
	BusinessGuarantorSignerResource,
	SigningDocumentResource,
	useResourceWithInvalidate
} from "@ploy-lib/rest-resources";

export interface ApplicationSigningProps {
	className?: string;
	applicationNumber?: string;
	signerMatrixVisible: boolean;
	mainSignerMatrixVisible: boolean;
	selfEmployedCustomer: boolean;
	esignType: EsignType;
	ssnServiceEnabled: boolean;
	externalUserCanNotAddSigners: boolean;
	showValidPeriod: boolean;
	canAddAndDeleteVendorSigners: boolean;
	canChooseSigners: boolean;
	canEditIndividualDocuments?: boolean;
	disableVendorSigner: boolean;
	hideVendorSigner: boolean;
	disableSupplierSigner: boolean;
	hideSupplierSigner: boolean;
	disableSignerEditing: boolean;
	mustSignDefaultChecked: boolean;
	onClick?: () => void;
	setSectionCompleted?: (
		id: TemplateSection["formTemplateSectionId"],
		completed: boolean
	) => void;
	formTemplateSectionId?: TemplateSection["formTemplateSectionId"];
	customerSignerMatrixReadOnly?: boolean;
	hideSignerList?: boolean;
	hideSignerEditing?: boolean;
	showAlternativeMessage?: boolean;
	hideSigningActions?: boolean;
	hideDocumentsToSign?: boolean;
	equityStatus: number;
}

const emptyArray = [];

const ApplicationSigning = (props: ApplicationSigningProps) => {
	const {
		className,
		applicationNumber,
		signerMatrixVisible,
		mainSignerMatrixVisible,
		onClick,
		selfEmployedCustomer,
		esignType,
		ssnServiceEnabled,
		setSectionCompleted,
		formTemplateSectionId,
		externalUserCanNotAddSigners,
		showValidPeriod,
		canAddAndDeleteVendorSigners,
		canChooseSigners,
		canEditIndividualDocuments = false,
		disableVendorSigner,
		hideVendorSigner,
		disableSupplierSigner,
		hideSupplierSigner,
		disableSignerEditing,
		customerSignerMatrixReadOnly = false,
		mustSignDefaultChecked = false,
		hideSignerList,
		hideSignerEditing,
		showAlternativeMessage,
		hideSigningActions,
		hideDocumentsToSign,
		equityStatus
	} = props;

	const [currentGuarantorSigner, setCurrentGuarantorSigner] = useState<
		number | null
	>(null);

	const documents =
		useResourceWithInvalidate(
			SigningDocumentResource.list(),
			applicationNumber
				? {
						applicationNumber
				  }
				: null
		) ?? emptyArray;

	const guarantors =
		useResourceWithInvalidate(
			BusinessGuarantorSignerResource.list(),
			applicationNumber
				? {
						applicationNumber
				  }
				: null
		) ?? emptyArray;

	const customerDocuments = documents.filter(
		x =>
			x.signerTypes.some(y => y === 0 || y === 1) ||
			x.signerTypesToRead.some(y => y === 0 || y === 1)
	);

	const vendorDocuments = documents.filter(
		x =>
			x.signerTypesToRead.some(y => y === 3) || x.signerTypes.some(y => y === 3)
	);

	const supplierDocuments = documents.filter(
		x =>
			x.signerTypesToRead.some(y => y === 5) || x.signerTypes.some(y => y === 5)
	);

	const otherResidualValueGuarantorDocuments = documents.filter(
		x =>
			x.signerTypesToRead.some(y => y === 6) || x.signerTypes.some(y => y === 6)
	);

	const completed = documents.every(doc => doc.signed) || false;

	useEffect(() => {
		if (formTemplateSectionId)
			setSectionCompleted?.(formTemplateSectionId, completed);
	}, [completed, formTemplateSectionId, setSectionCompleted]);

	const showSigners = !hideSignerEditing && signerMatrixVisible;

	const showCustomerSignerMatrix =
		showSigners && mainSignerMatrixVisible && customerDocuments.length > 0;

	const showVendorSignerMatrix =
		!hideVendorSigner && showSigners && vendorDocuments.length > 0;

	const showSupplierSignerMatrix =
		!hideSupplierSigner && showSigners && supplierDocuments.length > 0;

	const showOtherResidualValueGuarantorMatrix =
		showSigners && otherResidualValueGuarantorDocuments.length > 0;

	const showGuarantorSignerMatrix =
		!hideSignerEditing &&
		guarantors.length > 0 &&
		documents.filter(
			d =>
				d.guarantorMustSign !== undefined || d.guarantorMustRead !== undefined
		).length > 0;

	if (!applicationNumber) return null;

	return (
		<Grid container spacing={3} className={className} onClick={onClick}>
			<>
				<Grid item xs={12} style={{ paddingLeft: "0", paddingRight: "0" }}>
					<AppSigningStatus
						applicationNumber={applicationNumber}
						documents={documents}
						hideSigningActions={hideSigningActions}
					/>
				</Grid>
				{equityStatus !== 0 && (
					<Grid item xs={12}>
						<EquityStatus equityStatus={equityStatus} />
					</Grid>
				)}
			</>
			{showCustomerSignerMatrix && (
				<Grid item xs={12}>
					<Typography variant="subtitle2">
						<FormattedMessage
							id="core.application-signing.select-customer-signers"
							defaultMessage="CHOOSE CUSTOMER SIGNERS"
						/>
					</Typography>
					<AppSignatureInfo
						applicationNumber={applicationNumber}
						hideSignerList={hideSignerList}
						signerType={0}
					/>
					<AppSignerList
						applicationNumber={applicationNumber}
						documents={customerDocuments}
						esignType={
							esignType === EsignType.SsnNotRequiredForVendor
								? EsignType.SsnRequired
								: esignType
						}
						selfEmployedCustomer={selfEmployedCustomer}
						ssnServiceEnabled={ssnServiceEnabled}
						canAddAndDelete={!externalUserCanNotAddSigners}
						canChooseSigners={canChooseSigners}
						canEditIndividualDocuments={canEditIndividualDocuments}
						disabled={disableSignerEditing || customerSignerMatrixReadOnly}
						mustSignDefaultChecked={mustSignDefaultChecked}
						hideDocumentsToSign={hideDocumentsToSign}
						signerType={0}
					/>
				</Grid>
			)}
			{showGuarantorSignerMatrix && (
				<Grid item xs={12}>
					<Typography variant="subtitle2">
						<FormattedMessage
							id="core.application-signing.select-guarantor-signers"
							defaultMessage="VELG SIGNERINGSPLIKTIGE FOR KAUSJONIST"
						/>
					</Typography>
					{guarantors.map(
						g =>
							(!currentGuarantorSigner ||
								g.customerId === currentGuarantorSigner) && (
								<>
									<Typography variant="overline">{g.customerName}</Typography>
									<AppSignerList
										applicationNumber={applicationNumber}
										documents={documents.filter(
											d => (g.groupsToSign?.indexOf(d.documentCode) ?? -1) >= 0
										)}
										esignType={
											esignType === EsignType.SsnNotRequiredForVendor
												? EsignType.SsnRequired
												: esignType
										}
										ssnServiceEnabled={ssnServiceEnabled}
										onEdit={edit =>
											setCurrentGuarantorSigner(edit ? g.customerId : null)
										}
										showValidPeriod={showValidPeriod}
										canAddAndDelete={!externalUserCanNotAddSigners}
										canChooseSigners={canChooseSigners}
										canEditIndividualDocuments={canEditIndividualDocuments}
										mustSignDefaultChecked={mustSignDefaultChecked}
										guarantorId={g.customerId}
										disabled={false}
										hideDocumentsToSign={hideDocumentsToSign}
										signerType={4}
									/>
								</>
							)
					)}
				</Grid>
			)}
			{showVendorSignerMatrix && (
				<Grid item xs={12}>
					<Typography variant="subtitle2">
						<FormattedMessage
							id="core.application-signing.select-vendor-signers"
							defaultMessage="CHOOSE DEALERS SIGNERS"
						/>
					</Typography>
					<AppSignerList
						applicationNumber={applicationNumber}
						documents={vendorDocuments}
						esignType={
							esignType === EsignType.SsnNotRequiredForVendor
								? EsignType.SsnNotRequired
								: esignType
						}
						ssnServiceEnabled={ssnServiceEnabled}
						vendor
						showValidPeriod={showValidPeriod}
						canAddAndDelete={canAddAndDeleteVendorSigners}
						canChooseSigners={canChooseSigners}
						canEditIndividualDocuments={canEditIndividualDocuments}
						disabled={
							!disableVendorSigner ? disableVendorSigner : disableSignerEditing
						}
						mustSignDefaultChecked={mustSignDefaultChecked}
						hideDocumentsToSign={hideDocumentsToSign}
						signerType={3}
					/>
				</Grid>
			)}
			{showSupplierSignerMatrix && (
				<Grid item xs={12}>
					<Typography variant="subtitle2">
						<FormattedMessage
							id="core.application-signing.select-supplier-signers"
							defaultMessage="CHOOSE SUPPLIER SIGNERS"
						/>
					</Typography>
					<AppSignatureInfo
						applicationNumber={applicationNumber}
						hideSignerList={hideSignerList}
						signerType={5}
					/>
					<AppSignerList
						applicationNumber={applicationNumber}
						documents={supplierDocuments}
						esignType={
							esignType === EsignType.SsnNotRequiredForVendor
								? EsignType.SsnNotRequired
								: esignType
						}
						ssnServiceEnabled={ssnServiceEnabled}
						supplier={true}
						showValidPeriod={showValidPeriod}
						canAddAndDelete={!externalUserCanNotAddSigners}
						canChooseSigners={canChooseSigners}
						canEditIndividualDocuments={canEditIndividualDocuments}
						disabled={
							!disableSupplierSigner
								? disableSupplierSigner
								: disableSignerEditing
						}
						mustSignDefaultChecked={mustSignDefaultChecked}
						hideDocumentsToSign={hideDocumentsToSign}
						signerType={5}
					/>
				</Grid>
			)}
			{showOtherResidualValueGuarantorMatrix && (
				<Grid item xs={12}>
					<Typography variant="subtitle2">
						<FormattedMessage
							id="core.application-signing.select-residual-value-signers"
							defaultMessage="CHOOSE RESIDUAL VALUE SIGNERS"
						/>
					</Typography>
					<AppSignatureInfo
						applicationNumber={applicationNumber}
						hideSignerList={hideSignerList}
						signerType={6}
					/>
					<AppSignerList
						applicationNumber={applicationNumber}
						documents={otherResidualValueGuarantorDocuments}
						esignType={
							esignType === EsignType.SsnNotRequiredForVendor
								? EsignType.SsnNotRequired
								: esignType
						}
						ssnServiceEnabled={ssnServiceEnabled}
						showValidPeriod={showValidPeriod}
						canAddAndDelete={!externalUserCanNotAddSigners}
						canChooseSigners={canChooseSigners}
						canEditIndividualDocuments={canEditIndividualDocuments}
						disabled={true}
						mustSignDefaultChecked={mustSignDefaultChecked}
						hideDocumentsToSign={hideDocumentsToSign}
						signerType={6}
					/>
				</Grid>
			)}
			{showAlternativeMessage && (
				<Grid item xs={12}>
					<Typography>
						<FormattedMessage
							id="core.application-signing.signers-editable-elsewhere"
							defaultMessage="For oppsett av signeringspliktige, gå til Portalen."
						/>
					</Typography>
				</Grid>
			)}
		</Grid>
	);
};

ApplicationSigning.displayName = "DployApplicationSigning";

export { ApplicationSigning };
