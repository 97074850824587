import React from "react";
import clsx from "clsx";
import marked from "@ploy-lib/purify-marked";
import { makeStyles, getContrastRatio } from "@material-ui/core/styles";
import {
	OverrideProps,
	OverridableComponent
} from "@material-ui/core/OverridableComponent";

const useStyles = makeStyles(
	theme => ({
		root: {
			width: "100%",
			fontFamily: theme.typography.fontFamily,
			color: "inherit",
			"& .anchor-link": {
				marginTop: -96, // Offset for the anchor.
				position: "absolute"
			},
			'& pre, & pre[class*="language-"]': {
				margin: "24px 0",
				padding: "12px 18px",
				backgroundColor: theme.palette.background.paper,
				borderRadius: theme.shape.borderRadius,
				overflow: "auto",
				WebkitOverflowScrolling: "touch" // iOS momentum scrolling.
			},
			"& code": {
				display: "inline-block",
				lineHeight: 1.6,
				fontFamily: 'Consolas, "Liberation Mono", Menlo, Courier, monospace',
				padding: "3px 6px",
				color: "inherit",
				backgroundColor: theme.palette.background.paper,
				fontSize: 14
			},
			"& p code, & ul code, & pre code": {
				fontSize: 14,
				lineHeight: 1.6
			},
			"& h1": {
				...theme.typography.h1,
				color: theme.palette.text.secondary,
				margin: "32px 0 16px"
			},
			"& .description": {
				...theme.typography.h5,
				margin: "0 0 40px"
			},
			"& h2": {
				...theme.typography.h2,
				color: theme.palette.text.secondary,
				margin: "32px 0 24px"
			},
			"& h3": {
				...theme.typography.h3,
				color: theme.palette.text.secondary,
				margin: "32px 0 24px"
			},
			"& h4": {
				...theme.typography.h4,
				color: theme.palette.text.secondary,
				margin: "24px 0 16px"
			},
			"& p, & ul, & ol": {
				lineHeight: "inherit",
				fontSize: "inherit"
			},
			"& h1, & h2, & h3, & h4": {
				"& code": {
					fontSize: "inherit",
					lineHeight: "inherit",
					// Remove scroll on small screens.
					wordBreak: "break-word"
				},
				"& .anchor-link-style": {
					opacity: 0,
					// To prevent the link to get the focus.
					display: "none"
				},
				"&:hover .anchor-link-style": {
					display: "inline-block",
					opacity: 1,
					padding: "0 8px",
					color: theme.palette.text.hint,
					"&:hover": {
						color: theme.palette.text.secondary
					},
					"& svg": {
						width: "0.55em",
						height: "0.55em",
						fill: "currentColor"
					}
				}
			},
			"& table": {
				width: "100%",
				display: "block",
				overflowX: "auto",
				WebkitOverflowScrolling: "touch", // iOS momentum scrolling.
				borderCollapse: "collapse",
				borderSpacing: 0,
				overflow: "hidden",
				"& .prop-name": {
					fontSize: 13,
					fontFamily: 'Consolas, "Liberation Mono", Menlo, monospace'
				},
				"& .required": {
					color: theme.palette.type === "light" ? "#006500" : "#9bc89b"
				},
				"& .prop-type": {
					fontSize: 13,
					fontFamily: 'Consolas, "Liberation Mono", Menlo, monospace',
					color: theme.palette.type === "light" ? "#932981" : "#dbb0d0"
				},
				"& .prop-default": {
					fontSize: 13,
					fontFamily: 'Consolas, "Liberation Mono", Menlo, monospace',
					borderBottom: `1px dotted ${theme.palette.text.hint}`
				}
			},
			"& thead": {
				fontSize: 14,
				fontWeight: theme.typography.fontWeightMedium,
				color: theme.palette.text.secondary
			},
			"& tbody": {
				fontSize: 14,
				lineHeight: 1.5,
				color: theme.palette.text.primary
			},
			"& td": {
				borderBottom: `1px solid ${theme.palette.divider}`,
				padding: "8px 16px 8px 8px",
				textAlign: "left"
			},
			"& td:last-child": {
				paddingRight: 24
			},
			"& td compact": {
				paddingRight: 24
			},
			"& td code": {
				fontSize: 13,
				lineHeight: 1.6
			},
			"& th": {
				whiteSpace: "pre",
				borderBottom: `1px solid ${theme.palette.divider}`,
				fontWeight: theme.typography.fontWeightMedium,
				padding: "0 16px 0 8px",
				textAlign: "left"
			},
			"& th:last-child": {
				paddingRight: 24
			},
			"& tr": {
				height: 48
			},
			"& thead tr": {
				height: 64
			},
			"& strong": {
				fontWeight: theme.typography.fontWeightMedium
			},
			"& blockquote": {
				borderLeft: `5px solid ${theme.palette.text.hint}`,
				backgroundColor: theme.palette.background.paper,
				padding: "4px 24px",
				margin: "24px 0"
			},
			"& a, & a code": {
				// Style taken from the Link component
				color:
					getContrastRatio(
						theme.palette.primary.main,
						theme.palette.common.white
					) < 2.5
						? theme.palette.text.secondary
						: theme.palette.primary.main,
				textDecoration: "none",
				"&:hover": {
					textDecoration: "underline"
				}
			},
			"& img": {
				maxWidth: "100%"
			}
		}
	}),
	{ flip: false, name: "DployMarkdownElement" }
);

export interface MarkdownElementTypeMap<
	P = {},
	D extends React.ElementType = "div"
> {
	props: P & {
		text?: string;
		children?: React.ReactNode;
		inline?: boolean;
		postProcess?: (html: string) => string;
		options?: marked.MarkedOptions;
	};
	defaultComponent: D;
	classKey: MarkdownElementClassKey;
}

export type MarkdownElementClassKey = "root";

export type MarkdownElementProps<
	D extends React.ElementType = MarkdownElementTypeMap["defaultComponent"],
	P = {}
> = OverrideProps<MarkdownElementTypeMap<P, D>, D>;

export const MarkdownElement: OverridableComponent<MarkdownElementTypeMap> = <
	D extends React.ElementType = MarkdownElementTypeMap["defaultComponent"],
	P = {}
>(
	props: MarkdownElementProps<D, P>
) => {
	const {
		className,
		children = "",
		text = typeof children === "string" ? children : "",
		component: Component = "div",
		inline,
		postProcess,
		options: propOptions,
		...other
	} = props;

	const classes = useStyles(props);

	const options = {
		smartLists: true,
		...propOptions
	};

	const markdownText = text.replace(/\\n/g, "\n");

	const html = inline
		? marked.inlineLexer(markdownText, [], options)
		: marked(markdownText, options);

	if (!markdownText && typeof children !== "string")
		return (
			<Component className={className} {...other}>
				{children}
			</Component>
		);

	return (
		<Component
			className={clsx(classes.root, "markdown-body", className)}
			/* eslint-disable react/no-danger */
			dangerouslySetInnerHTML={{ __html: postProcess?.(html) ?? html }}
			{...other}
		/>
	);
};
