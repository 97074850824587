import { useMemo } from "react";
import { createTheme, darken, lighten } from "@material-ui/core/styles";

import { colorOverride } from "../colorOverrides";
import { createStatusColors } from "../statusColors";
import { Tenant } from "../types";
import { ThemeOptions } from "@material-ui/core/styles";
import { DployBrandTheme } from "@ploy-lib/types";
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
import { ComponentsProps } from "@material-ui/core/styles/props";
import { DployThemeOptions } from "..";

const migrationOverrides: ThemeOptions = {
	props: {
		MuiTextField: {
			variant: "outlined"
		},
		MuiLink: {
			underline: "always"
		}
	}
};

export function createDployTheme(
	design: string | undefined,
	tenant: Tenant,
	override: ThemeOptions
) {
	const {
		designs,
		themeObject = { palette: {} } as DployThemeOptions,
		designOverride
	} = tenant;

	const themeWithOverriddenPalette = {
		...themeObject,
		palette: { ...themeObject.palette, ...override.palette }
	};

	// createTheme generates light/dark/contrastText colors from the first argument
	let theme = createTheme(
		themeWithOverriddenPalette,
		migrationOverrides,
		override
	);

	theme.palette.status = createStatusColors(
		themeObject.palette?.status,
		theme.palette
	);

	if (design) {
		theme = designs[design]?.muiTheme(theme) ?? theme;
		theme = designOverride?.(theme) ?? theme;
	}
	theme = colorOverride(theme);

	return theme;
}

export function useDesignSettings(
	tenant: Tenant,
	design?: string,
	brandTheme: Partial<DployBrandTheme> = {}
) {
	const {
		palettePrimaryMain,
		paletteSecondaryMain,
		paletteTertiaryMain,
		paletteBackgroundDefault,
		paletteBackgroundDrawer,
		paletteOverride
	} = brandTheme;

	const theme = useMemo(() => {
		const overrideTheme = {
			palette: {} as PaletteOptions,
			props: {} as ComponentsProps
		};

		if (palettePrimaryMain)
			overrideTheme.palette.primary = { main: palettePrimaryMain };
		if (paletteSecondaryMain)
			overrideTheme.palette.secondary = { main: paletteSecondaryMain };

		if (paletteTertiaryMain)
			overrideTheme.palette.tertiary = {
				main: paletteTertiaryMain,
				light: lighten(paletteTertiaryMain, 0.1),
				dark: darken(paletteTertiaryMain, 0.5)
			};

		if (paletteBackgroundDefault)
			overrideTheme.palette.background = { default: paletteBackgroundDefault };

		if (paletteBackgroundDrawer)
			overrideTheme.props.Portal = { drawerColor: paletteBackgroundDrawer };

		if (paletteOverride) {
			var alternativePalette =
				tenant.themeObject?.alternativePalettes?.[paletteOverride];
			if (typeof alternativePalette !== "undefined")
				overrideTheme.palette = alternativePalette;
		}

		return createDployTheme(design, tenant, overrideTheme);
	}, [
		palettePrimaryMain,
		paletteSecondaryMain,
		paletteTertiaryMain,
		paletteBackgroundDefault,
		paletteBackgroundDrawer,
		paletteOverride,
		design,
		tenant
	]);

	return { theme };
}
