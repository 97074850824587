import {
	FormValidationResource,
	ValidatorEnumTypes
} from "@ploy-lib/rest-resources";
import { useFormikContext } from "formik";
import { useCallback } from "react";

const getPropByString = (
	values: Record<string, any>,
	propStringPath: string
) => {
	if (!propStringPath) return values;

	var prop,
		props = propStringPath.split(".");

	for (var i = 0; i < props.length - 1; i++) {
		prop = props[i];

		var candidate = values[prop];
		if (candidate !== undefined) {
			values = candidate;
		} else {
			break;
		}
	}
	return values[props[i]];
};

export const useEvaluationValidationExpression = (
	formValidations: FormValidationResource
) => {
	const { values }: Record<string, any> = useFormikContext();

	return useCallback(
		(namespacedFieldName: string) => {
			let isVisible = false; // Is initially hidden if field has Visibility
			let hasOnceBeenEvaluatedToTrue = false; // Flag to make evaluation: `isVisible = isVisible && evaluation` work at the bottom of the expressions-loop when `isVisible` initially is false

			for (let expression of formValidations[namespacedFieldName].expressions) {
				if (expression.validatorType !== ValidatorEnumTypes.Visibility)
					continue;

				let evaluation: boolean = true;

				const operator: string = expression.operator;
				const targetField: string = expression.targetField; // The field we wish to compare. This is the field whose value must match a given evaluation-value.

				// The value we wish to compare targetField against
				// This value is either a static value (such as "true", "false", "0", "1", "undefined", etc..),
				//	 or it is a string, like `Field:{Namespace}.{FieldName}`, which is used to look up the value of <Namespace>.<FieldName>
				let evaluationValue: string = expression.evaluationValue;

				if (evaluationValue.startsWith("Field:")) {
					// EvaluationValue is a string like `Field:{Namespace}.{FieldName}`, and not a static value,
					// we need to look up the value of <Namespace>.<FieldName>.
					// 	(PS: Just to be clear, and avoid confusion,
					//		the evaluationValue here is actually stored like for example: "Field:Calculator.Equity",
					//		without the curly-braces)
					let [evaluationNamespace, evaluationFieldName] =
						evaluationValue.split(".");
					evaluationNamespace = evaluationNamespace.split(":")[1];
					evaluationValue = values[evaluationNamespace]?.[evaluationFieldName];
				}

				// The value of the targetField, which we want to compare against the evaluation value.
				const targetValue = getPropByString(
					values,
					targetField.replace("undefined.", "") //namespace will be undefined for resource schemas
				);

				switch (operator) {
					case "===":
						evaluation =
							targetValue?.toString().toLowerCase() ===
							evaluationValue.toString().toLowerCase();
						break;
					case "!==":
						evaluation =
							targetValue?.toString().toLowerCase() !==
							evaluationValue.toString().toLowerCase();
						break;
					case ">":
						evaluation =
							Number(targetValue?.toString()) >
							Number(evaluationValue.toString());
						break;
					case ">=":
						evaluation =
							Number(targetValue?.toString()) >=
							Number(evaluationValue.toString());
						break;
					case "contains":
						const targetCompare =
							evaluationValue.toString() !== "FieldName"
								? evaluationValue.toString().toLowerCase()
								: namespacedFieldName.split(".")[1]?.toLowerCase();
						if (typeof targetValue?.filter === "function") {
							evaluation =
								targetValue?.length > 0 &&
								targetValue.filter(
									(multiselectEntry: string) =>
										multiselectEntry.toLowerCase() === targetCompare
								).length > 0;
						} else {
							evaluation = targetValue
								?.toString()
								.toLowerCase()
								.includes(targetCompare);
						}
						break;
					default:
						break;
				}
				if (!hasOnceBeenEvaluatedToTrue) {
					isVisible = evaluation;
					hasOnceBeenEvaluatedToTrue = true;
				} else {
					isVisible = isVisible && evaluation;
				}
			}
			return isVisible;
		},
		[formValidations, values]
	);
};
