import { apiResourceUrl } from "@ploy-lib/core";
import { BaseCamelCasedResource } from "./BasePlainResource";
import { EndpointExtraOptions } from "@rest-hooks/rest";

export interface ProductSettings {
	readonly useRoboticFollowUp?: boolean;
	readonly useEstablishmentFeeFinancingOption?: boolean;
	readonly useProductInformationMultipleDocuments?: boolean;
	readonly showValidLimitTypeSelection?: boolean;
	readonly showLimitProductSelection?: boolean;
	readonly activeLimitTypes?: string[];
	readonly useDefaultPhoenixDropdownProductSelectorMenu?: boolean;
}

export class ProductSettingsResource
	extends BaseCamelCasedResource
	implements ProductSettings {
	readonly useRoboticFollowUp: boolean = false;
	readonly useEstablishmentFeeFinancingOption: boolean = false;
	readonly useProductInformationMultipleDocuments: boolean = false;
	readonly showValidLimitTypeSelection: boolean = false;
	readonly showLimitProductSelection: boolean = false;
	readonly activeLimitTypes: string[] = [""];
	readonly useDefaultPhoenixDropdownProductSelectorMenu: boolean = true;
	readonly hideProductViewHeaderInSnippetContext: boolean = false;

	pk() {
		return "ProductSettingsResourceSingleton";
	}

	static getEndpointExtra(): EndpointExtraOptions {
		return {
			...super.getEndpointExtra(),
			invalidIfStale: true,
			dataExpiryLength: Infinity
		};
	}

	static url(urlParams: Readonly<Record<string, any>>): string {
		return this.listUrl(urlParams);
	}

	static async fetch(input: RequestInfo, init: RequestInit) {
		return await super.fetch(input, init);
	}

	static urlRoot = apiResourceUrl("products/productSettings");
}
