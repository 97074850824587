/** Based on suggestions from https://github.com/jquense/yup/issues/345 */
import * as Yup from "yup";

declare module "yup" {
	interface ObjectSchema<T> {
		uniqueProperty<P extends keyof T>(
			propertyName: P,
			message: string,
			compareFunction?: ((a: T[P], b: T[P]) => boolean) | null
		): ObjectSchema<T>;
	}
}

Yup.addMethod(Yup.object, "uniqueProperty", function <
	T
>(this: Yup.Schema<T>, propertyName: string, message: string, compareFunction: ((a: any, b: any) => boolean) | null = null) {
	return this.test("unique", message, function (testedObject) {
		if (!Array.isArray(this.parent)) return true;

		if (
			this.parent
				// Exclude the same object (by reference)
				.filter((object: any) => object !== testedObject)

				// Check for property match among some of the other objects
				.some((object: any) => {
					const objectValue = object?.[propertyName];
					const testedObjectValue = testedObject?.[propertyName];

					return compareFunction
						? compareFunction(objectValue, testedObjectValue)
						: objectValue === testedObjectValue;
				})
		) {
			return this.createError({
				path: `${this.path}.${propertyName}`,
				message
			});
		}

		return true;
	});
});
