import { useContext, useEffect } from "react";
import moment from "moment";
import {
	IThirdPartyScriptsContext,
	ThirdPartyScriptsContext
} from "./ThirdPartyScriptsContext";

export default function GoogleTagManagerExtended({
	GoogleTagManagerCode,
	CustomerCategoryId
}) {
	const thirdPartySettings = useContext(ThirdPartyScriptsContext);

	useEffect(() => {
		initGoogleTagManager(thirdPartySettings.google, GoogleTagManagerCode);
	}, [GoogleTagManagerCode]);

	useEffect(() => {
		gtmManagerIdentity(thirdPartySettings.google, CustomerCategoryId);
	}, [CustomerCategoryId]);

	return null;
}

const gtmManagerIdentity = (
	google: IThirdPartyScriptsContext["google"],
	type?: number
) => {
	if (type) {
		// Do not initialize if already initialized
		if (google.getHistory().some(x => x["gtm.start"])) return;

		//do nothing if type is falsy or 0
		let typeString;

		switch (type) {
			case 1:
				typeString = "bil";
				break;
			case 2:
				typeString = "gjenstand";
				break;
			default:
				break;
		}

		google.push({
			event: "identifisering",
			userType: typeString
		});
	}
};

const initGoogleTagManager = (
	google: IThirdPartyScriptsContext["google"],
	code?: string
) => {
	google.pushAll("init", {
		"gtm.start": new Date().getTime(),
		event: "gtm.js"
	});

	if (google.appendScript) {
		const firstScriptTag = document.getElementsByTagName("script")[0];
		const scriptTag = document.createElement("script");

		scriptTag.async = true;
		scriptTag.src = `https://www.googletagmanager.com/gtm.js?id=${code}`;

		firstScriptTag?.parentNode?.insertBefore(
			scriptTag,
			firstScriptTag.nextSibling
		);
	}
};

export const updateGTMVirtualPageViewsExtended = (
	google: IThirdPartyScriptsContext["google"],
	applicationNumber?: string,
	productName?: string,
	vendorName?: string,
	tabName?: string,
	startIndex: number = 0,
	pageIndex: number = 0,
	totalSteps: number = 0,
	isPostProcess?: boolean,
	includeProduct?: boolean,
	includeObjectCategory?: boolean,
	loanAmount?: number,
	objectCategory?: string,
	productId?: number,
	salesChannel?: string,
	fromSubmit?: boolean
) => {
	let virtualPageViewTitle = "";
	let virtualPageViewURL = "";
	let virtualPageViewURLPostfix = GTMCodeFromProductName(
		productName ?? "",
		includeProduct
	);

	if (isPostProcess) {
		virtualPageViewURL = `/søknad_sendt${virtualPageViewURLPostfix}`;

		if (
			!fromSubmit ||
			google
				.getHistory()
				.filter(x => x.virtualPageURL?.includes(virtualPageViewURL)).length > 0
		) {
			// Application post-process form is opened some time after submission. Don't post "Søknad sendt" events to Google Analytics if this is not a new application
			return;
		}

		virtualPageViewTitle = "søknad sendt";
	} else {
		if (tabName) {
			virtualPageViewURL = `/${tabName}${virtualPageViewURLPostfix}`;
			virtualPageViewTitle = `${tabName}`;
		}
	}

	const hotjarUri = `/soknad${virtualPageViewURLPostfix}/steg${pageIndex}`;

	if (
		virtualPageViewTitle &&
		virtualPageViewURL &&
		(!includeObjectCategory || objectCategory)
	) {
		const windowDataLayerURLs: string[] = google
			.getHistory()
			.filter(
				(item: any) =>
					item.event === "VirtualPageview" ||
					item.virtualPageTitle === virtualPageViewTitle
			)
			.map((item: any) => item.virtualPageURL);

		// Avoid virtual pageview duplicates
		if (windowDataLayerURLs.some(x => x === virtualPageViewURL)) {
			return;
		}

		let event = "";
		let checkout_step = 0;

		if (isPostProcess) {
			event = "purchase";
			checkout_step = 4;
		} else if (pageIndex === startIndex) {
			event = "begin_checkout";
			checkout_step = 1;
		} else if (totalSteps - pageIndex === 2) {
			event = "add_payment_info";
			checkout_step = 2;
		} else if (totalSteps - pageIndex === 1) {
			event = "add_shipping_info";
			checkout_step = 3;
		}

		const items = [
			{
				item_id: productId,
				item_name: productName,
				affiliation: vendorName,
				item_brand: salesChannel,
				item_category: objectCategory,
				quantity: "1"
			}
		];

		const ecom = {
			currency: "nok",
			value: loanAmount
		};

		if (isPostProcess) {
			items[0]["application_status"] = "Innsendt";
			ecom["application_date"] = moment(new Date()).format("YYYY-MM-DD");
			ecom["transaction_id"] = (applicationNumber?.split("-") ?? [""])[0];
		}

		ecom["items"] = items;

		let data = {
			event: event,
			checkout_step: checkout_step,
			virtualPageURL: virtualPageViewURL,
			virtualPageTitle: virtualPageViewTitle,
			ecommerce: ecom
		};

		// add_to_cart event. Only snippet solution has tabName === "objectinfo"
		if (pageIndex === startIndex && tabName === "objectinfo") {
			const cart = {
				event: "add_to_cart",
				ecommerce: ecom
			};
			google.push(cart);
		}

		data.ecommerce["items"][0]["price"] = loanAmount;

		google.push(data);

		//	Call hj() after pushing to dataLayer
		if (window["hj"]) {
			window["hj"]("vpv", hotjarUri);
		}
	}
};

const GTMCodeFromProductName = (
	productName: string,
	includeProduct?: boolean
) => {
	if (!includeProduct) return "";
	return (
		"-" +
		productName
			.toLowerCase()
			.replace(/ /g, "-")
			.replace(/æ/g, "ae")
			.replace(/ø/g, "oe")
			.replace(/å/g, "aa")
	);
};
