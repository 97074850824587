import React, {
	useReducer,
	useCallback,
	useMemo,
	createContext,
	useContext
} from "react";

export interface PageContextValue {
	labels: (string | undefined)[];
	step: number;
	lastStep: number;
	isLastStep: boolean;
	next: () => void;
	prev: () => void;
	goto: (step: number, replace?: boolean) => void;
	getStepHref: (step: number) => string | undefined;
	reset: () => void;
}

const defaultContext: PageContextValue = {
	labels: [undefined],
	step: 0,
	lastStep: 0,
	isLastStep: true,
	next: () => {},
	prev: () => {},
	goto: () => {},
	getStepHref: () => undefined,
	reset: () => {}
};

const Context = createContext<PageContextValue>(defaultContext);
Context.displayName = "PageState";

export const PageProvider = Context.Provider;
export const PageConsumer = Context.Consumer;

export function usePageState() {
	return useContext(Context);
}

export function usePageStateManager(
	labels: (string | undefined)[],
	initialStep = 0
) {
	const numberOfSteps = labels.length;

	const [step, dispatch] = useReducer(
		(
			state: number,
			action:
				| { type: "next" | "prev" | "reset" }
				| { type: "goto"; payload: number }
		) => {
			let nextStep = state;
			switch (action.type) {
				case "next":
					nextStep = state + 1;
					break;
				case "prev":
					nextStep = state - 1;
					break;
				case "goto":
					nextStep = action.payload;
					break;
				case "reset":
					nextStep = initialStep;
					break;
				default:
					break;
			}

			if (nextStep >= 0 && nextStep <= numberOfSteps) return nextStep;

			return state;
		},
		initialStep
	);

	const next = useCallback(() => dispatch({ type: "next" }), []);
	const prev = useCallback(() => dispatch({ type: "prev" }), []);
	const goto = useCallback(
		(step: number) => dispatch({ type: "goto", payload: step }),
		[]
	);
	const reset = useCallback(() => dispatch({ type: "reset" }), []);

	return useMemo(
		() => ({
			labels,
			next,
			prev,
			goto,
			reset,
			step,
			getStepHref: defaultContext.getStepHref,
			lastStep: numberOfSteps - 1,
			isLastStep: step === numberOfSteps - 1
		}),
		[labels, next, prev, goto, reset, step, numberOfSteps]
	);
}

export interface PageStateProviderProps {
	initialStep?: number;
	labels: (string | undefined)[];
	children: React.ReactNode;
}

export function PageStateProvider({
	initialStep = 0,
	labels,
	children
}: PageStateProviderProps) {
	const state = usePageStateManager(labels, initialStep);

	return <PageProvider value={state}>{children}</PageProvider>;
}
