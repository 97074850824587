import { FormTemplate, TemplateField, TemplatePage } from "@ploy-lib/types";
import { useMemo } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
	object as YupObject,
	string as YupString,
	array as YupArray,
	number as YupNumber
} from "yup";

const validationMessages = defineMessages({
	required: {
		id: "calculation-form.validation.required",
		defaultMessage: "Required"
	}
});

// useSpecialFieldHandling is a hook to handle overriding default form/ field behaviour.
/* Returns:
{
	validationSchema: a custom "required" - validation scheme for TemplateFields marked `IsRequired`
	initialFieldValues: initial values for fields. May only be set for TemplateFields that are marked `CgfHandling: "Ignore"`
}
*/

const getFieldValueType = (field: TemplateField) => {
	if (field.multiple) return "array";
	if (field.renderAs === "NumberField") return "number";
	return "string";
};

export const useSpecialFieldHandling = (
	formTemplate: FormTemplate<TemplatePage> | undefined
) => {
	const intl = useIntl();

	const stuff = useMemo(() => {
		if (!formTemplate) return {};

		const allTemplateFieldsInForm: TemplateField[] = formTemplate.pages
			.map(page => page.panels)
			.flatMap(panelDictForPage => Object.values(panelDictForPage)) // page.panels is a dictionary, flatmap out the values for panels
			.flatMap(panel => panel.sections.flatMap(section => section.fields));

		// { namespace_1: requiredFields[], namespace_2: requiredFields[] }
		const requiredFields: Record<
			string,
			{ name: string; fieldValueType: string }[]
		> = allTemplateFieldsInForm
			.filter((field: TemplateField) => field.isRequired)
			.map((field: TemplateField) => ({
				name: field.name,
				namespace: field.namespace,
				fieldValueType: getFieldValueType(field)
			}))
			.reduce((result, reqField) => {
				const namespace = reqField.namespace!;
				// prevent adding `undefined` to output array when spreading ...[res[f.namespace!]]
				if (namespace in result) {
					return {
						...result,
						[namespace]: [
							...result[namespace],
							{
								name: reqField.name,
								fieldValueType: reqField.fieldValueType
							}
						]
					};
				}
				return {
					...result,
					[namespace]: [
						{
							name: reqField.name,
							fieldValueType: reqField.fieldValueType
						}
					]
				};
			}, {});

		const validationSchema = YupObject().shape(
			Object.keys(requiredFields).reduce((schemaResult, namespaceKey) => {
				return {
					...schemaResult,
					[namespaceKey]: YupObject().shape(
						requiredFields[namespaceKey].reduce((namespaceResult, field) => {
							let validationObject = {};
							if (field.fieldValueType === "array") {
								validationObject[field.name] = YupArray().required(
									intl.formatMessage(validationMessages.required)
								);
							} else if (field.fieldValueType === "number") {
								validationObject[field.name] = YupNumber().typeError(
									intl.formatMessage(validationMessages.required)
								);
							} else {
								validationObject[field.name] = YupString().required(
									intl.formatMessage(validationMessages.required)
								);
							}
							return {
								...namespaceResult,
								...validationObject
							};
						}, {})
					)
				};
			}, {})
		);

		// { namespace_1: initialFieldValues[], namespace_2: initialFieldValues[] }
		const initialFieldValues: Record<
			string,
			Record<string, any>
		> = allTemplateFieldsInForm
			.filter((field: TemplateField) => field.initialFieldValue)
			.map((field: TemplateField) => ({
				name: field.name,
				namespace: field.namespace!,
				fieldValue: field.initialFieldValue!
			}))
			.reduce(
				(accumulated, field) => ({
					...accumulated,
					[field.namespace]: {
						...accumulated[field.namespace],
						[field.name]: field.fieldValue
					}
				}),
				{}
			);

		return {
			initialFieldValues,
			validationSchema
		};
	}, [formTemplate, intl]);

	return stuff;
};
